import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { moveToLoginPage } from './utils'
import { AUTOMATION, EMAIL, INBOUND, MANUALLY, OUTBOUND, SMS } from './devOptions.js'
import PrivateRoute, { URValidator } from './PrivateRoute'
import authActions from './store/modules/authActions'
import OuterLayout from './layout/OuterLayout/OuterLayout'
import Login from './views/profile/login/index.js'
import LoginVerification from './views/profile/login/LoginVerification.js'
import LoginVerificationGetCode from './views/profile/login/LoginVerificationGetCode.js'
import PasswordRequest from './views/profile/login/PasswordRequest.js'
import PasswordReset from './views/profile/login/PasswordReset.js'
import StripeReturn from './components/api/StripeReturn.js'
import StripeAuth from './components/api/StripeAuth.js'
import Popup from './components/Popup/Popup.js'
import MessageMain from './views/messages/main/MessageMain.js'
import MainLayout from './layout/MainLayout/MainLayout.js'
import ProfileMain from './views/profile/ProfileMain.js'
import ProfileEdit from './views/profile/ProfileEdit.js'
import ProfileChangePassword from './views/profile/ProfileChangePassword.js'
import ProfileNotification from './views/profile/ProfileNotification.js'
import CreateCampaign from './views/broadcast/CreateCampaign/CreateCampaign.js'
import CreateByUpload from './views/broadcast/CreateCampaign/CreateByUpload/CreateByUpload.js'
import CreateSegment from './views/broadcast/CreateCampaign/CreateSegment/CreateSegment.js'
import StepPages from './views/broadcast/CreateCampaign/StepPages/StepPagesContent/StepPages.js'
import Invitation from './views/invitation/index.js'
import Leaderboard from './views/invitation/leaderboard/index.js'
import Appointment from './views/appointment/index.js'
import DepositDetails from './views/payment/depositsList/DepositDetails.js'
import OrganizationsList from './views/organization/list/OrganizationsList.js'
import General from './views/organization/settings/general/index.js'
import Tags from './views/organization/settings/tags/index.js'
import DripSequence from './views/organization/settings/dripsequence/DripSequence.js'
import DripSequenceTemplate from './views/organization/settings/dripsequence_template/DripSequenceTemplate.js'
import DripSequenceEdit from './views/organization/settings/dripsequence/DripSequenceEdit.js'
import DripSequenceEditTemplate from './views/organization/settings/dripsequence_template/DripSequenceEditTemplate.js'
import Touchpoints from './views/organization/settings/touchpoints/index.js'
import Restrictions from './views/organization/settings/restrictions/index.js'
import Templates from './views/organization/settings/templates/index.js'
import TemplateEdit from './views/organization/settings/templates/TemplateEdit.js'
import WorkflowEdit from './views/organization/settings/workflow/WorkflowEdit.js'
import Workflow from './views/organization/settings/workflow/index.js'
import OpeningHours from './views/organization/settings/opening_hours/index.js'
import Superhuman from './views/organization/settings/superhuman/index.js'
import IPWhiteList from './views/organization/settings/ip_white_list/index.js'
import Users from './views/users/index.js'
import LocationList from './views/locations/list/index.js'
import EditLocation from './views/locations/settings/edit/index.js'
import LocationIntegration from './views/locations/settings/integration/index.js'
import LocationReviewInvite from './views/locations/settings/reviewInvite/index.js'
import CalendarInvite from './views/locations/settings/calendarInvite/index.js'
import TextMe from './views/locations/settings/textMe/index.js'
import IntegratedCta from './views/locations/settings/cta/index.js'
import Settings from './views/locations/settings/matadorConnect/settings/index.js'
import Questions from './views/locations/settings/matadorConnect/questions/index.js'
import Appearance from './views/locations/settings/matadorConnect/appearance/index.js'
import Greetings from './views/locations/settings/matadorConnect/greetings/index.js'
import GreetingEdit from './views/locations/settings/matadorConnect/greetings/GreetingEdit.js'
import Announcements from './views/locations/settings/matadorConnect/announcements/index.js'
import AnnouncementEdit from './views/locations/settings/matadorConnect/announcements/AnnouncementEdit.js'
import ListStories from './views/locations/settings/matadorConnect/stories/index.js'
import AddEditStory from './views/locations/settings/matadorConnect/stories/AddEditStory.js'
import LocationsCoupon from './views/locations/settings/promotions/mobileCoupon/LocationsCoupon.js'
import LocationsExitIntent from './views/locations/settings/promotions/exitIntent/LocationsExitIntent.js'
import SubscriberGrowth from './views/locations/settings/subscriberGrowth/index.js'
import FlowDesignerScreen from './views/locations/settings/matadorConnect/flowDesigner/index.js'
import RedirectMagic from './views/redirects/RedirectMagic.js'
import CreateConversation from './views/redirects/CreateConversation.js'
import ExtensionProfileInfo from './views/redirects/extensionProfileInfo/index.js'
import ShortLinkRedirect from './views/redirects/ShortLinkRedirect.js'
import ErrorBox from './components/ErrorBox/index.js'
import PaymentList from './views/payment/index.js'
import BroadcastingList from './views/broadcast/BroadcastList/BroadcastList.js'
import Dashboard from './views/dashboard/Dashboard.js'
import SettingsTransfer from './views/organization/settings/settings_transfer/index.js'
import VoiceAi from './views/organization/settings/voice_ai/VoiceAi.js'
import VoiceAiEdit from './views/organization/settings/voice_ai/VoiceAiEdit.js'
import CustomAskConsentMessages from './views/organization/settings/customAskConsentMessages/CustomAskConsentMessages.js'

const Root = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const userData = authActions.getUserData()
    if (authActions.isLoggedIn()) {
      const redirectTo = localStorage.getItem('redirectAfterLogin')
      localStorage.removeItem('redirectAfterLogin')

      if (redirectTo && URValidator(redirectTo)) {
        navigate(redirectTo)
      } else {
        if (authActions.getIsMobileApp()) {
          navigate('/messages')
        } else if (
          userData?.isAdmin ||
          userData?.isManager ||
          (userData?.isSupportAgent &&
            userData?.accessibleOrganizations &&
            userData?.accessibleOrganizations.length > 1)
        ) {
          navigate('/dashboard')
        } else {
          navigate('/messages')
        }
      }
    } else {
      moveToLoginPage(navigate)
    }
  }, [])

  return null
}

const routes = (
  <Routes>
    <Route path='/login' exact element={<OuterLayout>{<Login />}</OuterLayout>} />
    <Route
      path='/login_maglink'
      element={<OuterLayout>{<Login magicLink />}</OuterLayout>}
    />
    <Route
      path='/login/verification'
      element={<OuterLayout>{<LoginVerification />}</OuterLayout>}
    />
    <Route
      path='/login/verification'
      element={<OuterLayout>{<LoginVerification />}</OuterLayout>}
    />
    <Route
      path='/login/verification/code'
      element={<OuterLayout>{<LoginVerificationGetCode />}</OuterLayout>}
    />
    <Route
      path='/password_request'
      exact
      element={<OuterLayout>{<PasswordRequest />}</OuterLayout>}
    />
    <Route
      path='/reset'
      exact
      element={<OuterLayout>{<PasswordReset />}</OuterLayout>}
    />
    <Route path='/stripe/return/:id' element={<StripeReturn />} />
    <Route path='/stripe/reauth/:id' element={<StripeAuth />} />
    <Route path='/popup' element={<Popup />} />
    <Route
      path='/dashboard'
      element={<PrivateRoute noFooter>{<Dashboard />}</PrivateRoute>}
    />
    <Route
      exact
      path='/messages/extension'
      element={
        <MainLayout nofooter isExtension>
          <MessageMain isExtension />
        </MainLayout>
      }
    />
    <Route
      path={'/messages'}
      element={
        <PrivateRoute noFooter>
          <MessageMain />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path='/profile/edit'
      element={
        <MainLayout>
          <ProfileMain>
            <ProfileEdit />
          </ProfileMain>
        </MainLayout>
      }
    />

    <Route
      path='/profile/change-password'
      exact
      element={
        <MainLayout>
          <ProfileMain>
            <ProfileChangePassword />
          </ProfileMain>
        </MainLayout>
      }
    />
    <Route
      path='/profile/notification'
      exact
      element={
        <MainLayout>
          <ProfileMain>
            <ProfileNotification />
          </ProfileMain>
        </MainLayout>
      }
    />

    <Route
      path='/broadcasting/list'
      element={
        <PrivateRoute noFooter>
          <BroadcastingList createdBy={MANUALLY} />
        </PrivateRoute>
      }
    />
    <Route
      path='/automation/list'
      element={
        <PrivateRoute noFooter>
          <BroadcastingList createdBy={AUTOMATION} />
        </PrivateRoute>
      }
    />
    <Route
      path='/broadcasting/create-campaign'
      element={
        <PrivateRoute noFooter>
          <CreateCampaign />
        </PrivateRoute>
      }
    />
    <Route
      path='/broadcasting/create-campaign/file-upload'
      element={
        <PrivateRoute noFooter>
          <CreateByUpload />
        </PrivateRoute>
      }
    />
    <Route
      path='/broadcasting/create-campaign/create-segment'
      element={
        <PrivateRoute noFooter>
          <CreateSegment />
        </PrivateRoute>
      }
    />
    <Route
      path='/broadcasting/create-campaign/sub-pages'
      element={
        <PrivateRoute noFooter>
          <StepPages />
        </PrivateRoute>
      }
    />

    <Route
      path='/invitation/list'
      element={
        <PrivateRoute noFooter>
          <Invitation />
        </PrivateRoute>
      }
    />
    <Route
      path='/leaderboard'
      element={
        <PrivateRoute noFooter>
          <Leaderboard />
        </PrivateRoute>
      }
    />

    <Route
      path='/appointments'
      element={
        <PrivateRoute noFooter>
          <Appointment />
        </PrivateRoute>
      }
    />

    <Route
      path='/payments'
      element={
        <PrivateRoute noFooter>
          <PaymentList />
        </PrivateRoute>
      }
    />
    <Route
      path='/payment/:_id/deposit-details'
      element={
        <PrivateRoute noFooter>
          <DepositDetails />
        </PrivateRoute>
      }
    />

    <Route
      path='/organization/list'
      element={
        <PrivateRoute noFooter>
          <OrganizationsList />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/general'
      element={
        <PrivateRoute>
          <General />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/tags'
      element={
        <PrivateRoute noFooter>
          <Tags />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/dripsequence/list'
      element={
        <PrivateRoute noFooter>
          <DripSequence />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/dripsequence/template/list'
      element={
        <PrivateRoute noFooter>
          <DripSequenceTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/dripsequence/create'
      element={
        <PrivateRoute>
          <DripSequenceEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/dripsequence/edit'
      element={
        <PrivateRoute>
          <DripSequenceEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/dripsequence/template/create'
      element={
        <PrivateRoute>
          <DripSequenceEditTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/dripsequence/template/edit'
      element={
        <PrivateRoute>
          <DripSequenceEditTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/touchpoints'
      element={
        <PrivateRoute noFooter>
          <Touchpoints />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/inbound'
      element={
        <PrivateRoute noFooter>
          <VoiceAi type={INBOUND} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/outbound'
      element={
        <PrivateRoute noFooter>
          <VoiceAi type={OUTBOUND} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/restrictions'
      element={
        <PrivateRoute>
          <Restrictions />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/templates/sms/list'
      element={
        <PrivateRoute noFooter>
          <Templates templateType={SMS} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/templates/email/list'
      element={
        <PrivateRoute noFooter>
          <Templates templateType={EMAIL} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/template/sms/create'
      element={
        <PrivateRoute>
          <TemplateEdit templateType={SMS} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/template/email/create'
      element={
        <PrivateRoute>
          <TemplateEdit templateType={EMAIL} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/template/sms/edit'
      element={
        <PrivateRoute>
          <TemplateEdit templateType={SMS} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/template/email/edit'
      element={
        <PrivateRoute>
          <TemplateEdit templateType={EMAIL} />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/workflow/list'
      element={
        <PrivateRoute noFooter>
          <Workflow />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/workflow/create'
      element={
        <PrivateRoute>
          <WorkflowEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/workflow/edit'
      element={
        <PrivateRoute>
          <WorkflowEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/inbound/create'
      element={
        <PrivateRoute>
          <VoiceAiEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/inbound/edit'
      element={
        <PrivateRoute>
          <VoiceAiEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/outbound/create'
      element={
        <PrivateRoute>
          <VoiceAiEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/voice_ai/outbound/edit'
      element={
        <PrivateRoute>
          <VoiceAiEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/opening_hours'
      element={
        <PrivateRoute>
          <OpeningHours />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/superhuman'
      element={
        <PrivateRoute>
          <Superhuman />
        </PrivateRoute>
      }
    />
    <Route
      path='/organization/settings/:_id/ip_whitelist'
      element={
        <PrivateRoute>
          <IPWhiteList />
        </PrivateRoute>
      }
    />

    <Route
      path='/organization/settings/:_id/settings_transfer'
      element={
        <PrivateRoute>
          <SettingsTransfer />
        </PrivateRoute>
      }
    />

    <Route
      path='/organization/settings/:_id/custom_ask_consent_messages'
      element={
        <PrivateRoute>
          <CustomAskConsentMessages />
        </PrivateRoute>
      }
    />

    <Route
      path='/users'
      element={
        <PrivateRoute noFooter>
          <Users />
        </PrivateRoute>
      }
    />

    <Route
      path='/location/list'
      element={
        <PrivateRoute noFooter>
          <LocationList />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/new'
      element={
        <PrivateRoute>
          <EditLocation />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/edit'
      element={
        <PrivateRoute>
          <EditLocation />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/integration'
      element={
        <PrivateRoute noFooter>
          <LocationIntegration />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/review'
      element={
        <PrivateRoute>
          <LocationReviewInvite />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/calendar'
      element={
        <PrivateRoute>
          <CalendarInvite />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/cta/mobile-cta'
      element={
        <PrivateRoute>
          <TextMe />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/cta/integrated-cta'
      element={
        <PrivateRoute>
          <IntegratedCta />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-main'
      element={
        <PrivateRoute>
          <Settings />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-appearance'
      element={
        <PrivateRoute>
          <Appearance />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-questions'
      element={
        <PrivateRoute>
          <Questions />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-greetings'
      element={
        <PrivateRoute>
          <Greetings />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-greetings/add'
      element={
        <PrivateRoute>
          <GreetingEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-greetings/edit'
      element={
        <PrivateRoute>
          <GreetingEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-announcements'
      element={
        <PrivateRoute>
          <Announcements />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-announcements/add-special'
      element={
        <PrivateRoute>
          <AnnouncementEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-announcements/edit-special'
      element={
        <PrivateRoute>
          <AnnouncementEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-announcements/add-announcement'
      element={
        <PrivateRoute>
          <AnnouncementEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/livechat-announcements/edit-announcement'
      element={
        <PrivateRoute>
          <AnnouncementEdit />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/stories/list'
      element={
        <PrivateRoute noFooter>
          <ListStories />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/stories/create'
      element={
        <PrivateRoute>
          <AddEditStory isNew />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/stories/edit'
      element={
        <PrivateRoute>
          <AddEditStory />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/promotions/mobileCoupon'
      element={
        <PrivateRoute>
          <LocationsCoupon />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/promotions/exitIntent'
      element={
        <PrivateRoute>
          <LocationsExitIntent />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/subsrciber-growth'
      element={
        <PrivateRoute>
          <SubscriberGrowth />
        </PrivateRoute>
      }
    />
    <Route
      path='/location/settings/:_id/flow-designer'
      element={
        <PrivateRoute noFooter>
          <FlowDesignerScreen />
        </PrivateRoute>
      }
    />

    <Route path='/magic' element={<RedirectMagic />} />
    <Route path='/create_conversation' element={<CreateConversation />} />
    <Route
      path='/profile-info'
      element={
        <OuterLayout isBlueExtension>
          <ExtensionProfileInfo />
        </OuterLayout>
      }
    />
    <Route path='/s/:_id' element={<ShortLinkRedirect />} />
    <Route path='/' element={<Root />} />
    <Route path={'*'} element={<ErrorBox />} />
  </Routes>
)

export default routes
