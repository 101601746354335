import { getText } from '../lang'

const utilsLocalizations = {
  localizedText: (text, whoSent_this) => {
    let localizedText = text
    switch (text) {
      case 'sell':
        localizedText = getText('WORD_SELL').toLowerCase()
        break
      case 'buy':
        localizedText = getText('WORD_BUY').toLowerCase()
        break
      case 'Automations changed the consent status of this customer from Pending to Refused':
        localizedText = getText(
          'TEXT_AUTOMATIONS_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_PENDING_REFUSED'
        )
        break
      case 'Automations changed the consent status of the customer from Compliant to Opted-out':
        localizedText = getText(
          'TEXT_AUTOMATIONS_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_COMPLIANT_OPTED_OUT'
        )
        break
      case 'Automations changed the consent status of this customer from Compliant to Refused':
        localizedText = getText(
          'TEXT_AUTOMATIONS_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_COMPLIANT_REFUSED'
        )
        break
      case `${whoSent_this} changed the consent status of this customer from Compliant to Refused`:
        localizedText = `${whoSent_this} ${getText(
          'TEXT_USERNAME_CHANGED_CONSENT_STATUS_THIS_CUSTOMER_COMPLIANT_REFUSED'
        )}`
        break
      default:
        break
    }
    return localizedText
  },
  localizedServerError: (error) => {
    let localizedText = error
    if (
      error.startsWith(
        'This email is already in use for the ‘Integrate Your Touchpoint'
      )
    ) {
      localizedText = getText(
        'TEXT_THIS_EMAIL_ALREADY_IN_USE_FOR_INTEGRATE_YOUR_TOUCHPOINT_FEATURE_PLEASE_TRY_DIFFERENT_EMAIL'
      )
    }
    if (
      error.startsWith(
        'This email is already in use for the ‘Share a copy of the leads with another service'
      )
    ) {
      localizedText = getText(
        'TEXT_THIS_EMAIL_ALREADY_IN_USE_FOR_SHARE_COPY_LEADS_ANOTHER_SERVICE_FEATURE_PLEASE_TRY_DIFFERENT_EMAIL'
      )
    }
    if (error.startsWith('This email is already connected to another location.')) {
      localizedText = getText(
        'TEXT_THIS_EMAIL_ALREADY_CONNECTED_ANOTHER_LOCATION_PLEASE_USE_ANOTHER_EMAIL_INTEGRATE_TOUCHPOINTS'
      )
    }
    if (error.startsWith('Please provide 3 consent messages for each language')) {
      localizedText = getText(
        'TEXT_PLEASE_PROVIDE_CONSENT_MESSAGES_FOR_EACH_LANGUAGE'
      )
    }
    return localizedText
  },
}

export default utilsLocalizations
