import React, { memo } from 'react'
import { Modal } from 'antd'
import { getText } from '../../lang'
import Div from '../Div/Div'
import MyDefaultButton from '../MyDefaultButton/MyDefaultButton'
import './MyDefaultModal.scss'

const MyDefaultModal = ({
  noTrigger,
  dataIndex,
  triggerClassName,
  visible,
  onTrrigerClick,
  id,
  trigger,
  className,
  rootClassName,
  title,
  width,
  bodyStyle,
  maskStyle,
  destroyOnClose = true,
  hideFooter,
  onCancelBtn,
  onCancel,
  cancelText,
  hideSubmit,
  onOk,
  confirmLoading,
  okText,
  formName,
  submitButtonDisabled,
  centered = true,
  forceRender,
  closable,
  children,
}) => {
  return (
    <>
      {!noTrigger && (
        <Div
          dataIndex={dataIndex}
          className={`my_default_modal_btn ${triggerClassName || ''} ${visible ? 'active' : ''}`}
          onClick={() => {
            onTrrigerClick && onTrrigerClick()
          }}
          id={id}
        >
          {trigger || 'Open Modal'}
        </Div>
      )}
      <Modal
        className={`my_default_modal ${className || ''}`}
        rootClassName={rootClassName}
        title={title}
        open={visible}
        width={width || 500}
        styles={{ body: bodyStyle, mask: maskStyle }}
        destroyOnClose={destroyOnClose}
        footer={
          !hideFooter
            ? [
                <MyDefaultButton
                  key='back'
                  onClick={onCancelBtn || onCancel}
                  buttonText={cancelText || getText('ACTION_CANCEL')}
                  typeButton={'cancel'}
                  htmlType={'button'}
                  style={{ minWidth: 90, height: 50 }}
                />,
                !hideSubmit ? (
                  <MyDefaultButton
                    key='submit'
                    onClick={onOk}
                    loading={confirmLoading}
                    buttonText={okText}
                    formName={formName}
                    disabled={submitButtonDisabled}
                    style={{ minWidth: 90, height: 50 }}
                  />
                ) : null,
              ]
            : null
        }
        centered={centered}
        forceRender={forceRender}
        okText={okText}
        cancelText={cancelText}
        confirmLoading={confirmLoading}
        onOk={onOk}
        onCancel={onCancel}
        closable={closable}
      >
        <Div className='my_default_modal--content_wrapper'>{children}</Div>
      </Modal>
    </>
  )
}

export default memo(MyDefaultModal)
