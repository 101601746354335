import React from 'react'
import { Flex, Image } from 'antd'
import { FilePdfOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { getText } from '../../../lang'
import SVGMyDefaultDeleteIcon from '../../../icons/SVG/SVGMyDefaultDeleteIcon'
import SVGUploadExcel from '../../../icons/SVG/SVGUploadExcel'
import MyDefaultUpload from '../MyDefaultUpload'
import LoadingSpinner from '../../LoadingSpinner'
import Div from '../../Div/Div'
import './MyDefaultUploadWrapperBig.scss'
import { IMAGE, PDF, VIDEO } from '../../../devOptions'

const uploadedItemStyles = { fontSize: '70px' }

const MyDefaultUploadWrapperBig = (props) => {
  const {
    title,
    loading,
    onDelete,
    uploadedItemObj,
    disabled,
    listType = 'picture-card',
    subtitle,
  } = props

  const showUploadedItemFunc = (obj) => {
    switch (obj?.type) {
      case VIDEO:
        if (obj.showPreview && obj.url) {
          return (
            <video
              controls
              autoPlay
              style={{
                width: 176,
                height: 117,
                borderRadius: 12,
                objectFit: 'cover',
              }}
            >
              <source src={obj.url} />
              {getText('ERROR_SORRY_YOUR_BROWSER_DOESNT_SUPPORT_EMBEDDED_VIDEOS')}
            </video>
          )
        } else {
          return <VideoCameraOutlined style={uploadedItemStyles} />
        }
      case IMAGE:
        return <Image src={obj?.url} />
      case PDF:
        return <FilePdfOutlined style={{ ...uploadedItemStyles, color: 'red' }} />
      default:
        return ''
    }
  }

  const showUploadedItem = showUploadedItemFunc(uploadedItemObj)

  if (showUploadedItem) {
    return (
      <Div
        className={`my_default_upload ${props?.disableMargins ? 'disableMargins' : ''} ${props?.mediaCount === 1 ? '' : ''} my_default_upload_wrapper_big--item_wrapper`}
      >
        <Div
          className={`my_default_upload_wrapper_big--item_wrapper--inner  ${props?.mediaCount === 1 ? 'centered' : ''}`}
        >
          {showUploadedItem}
          <Div
            className={'my_default_upload_wrapper_big--item_wrapper--inner-delete'}
            onClick={onDelete}
          >
            <SVGMyDefaultDeleteIcon />
          </Div>
        </Div>
      </Div>
    )
  }

  return (
    <MyDefaultUpload
      {...props}
      listType={listType}
      className={'my_default_upload_wrapper_big'}
      disabled={disabled || loading}
      itemRender={(_, file, fileList, actions) => {
        const obj = {
          type: PDF,
        }

        switch (true) {
          case file.mediaContentType.includes(PDF):
            obj.type = PDF
            break
          case file.mediaContentType.includes(IMAGE):
            obj.type = IMAGE
            obj.url = file.mediaUrl
            break
          case file.mediaContentType.includes(VIDEO):
            obj.type = VIDEO
            break

          default:
            break
        }

        return (
          <Div
            className='my_default_upload_wrapper_big--item_wrapper'
            style={{ cursor: 'pointer' }}
            onClick={
              obj.type === PDF
                ? () => {
                    actions.download(file)
                  }
                : null
            }
          >
            <Div className={'my_default_upload_wrapper_big--item_wrapper--inner'}>
              {showUploadedItemFunc(obj)}
              <Div
                className={
                  'my_default_upload_wrapper_big--item_wrapper--inner-delete'
                }
                onClick={(e) => {
                  e.stopPropagation()
                  if (onDelete) {
                    const newList = fileList.filter(
                      (fileItem) => fileItem._id !== file._id
                    )

                    onDelete(newList)
                  }
                }}
              >
                <SVGMyDefaultDeleteIcon />
              </Div>
            </Div>
          </Div>
        )
      }}
    >
      <Flex vertical gap={20} align='center'>
        <React.Fragment>
          <SVGUploadExcel
            width={listType === 'picture-card' ? 40 : ''}
            height={listType === 'picture-card' ? 36 : ''}
            color={'var(--mainColor)'}
            listcolor={'#b3b9ff'}
          />
          <span className='my_default_upload_wrapper_big--text'>
            {loading ? (
              <LoadingSpinner style={{ height: 44 }} withoutHeight />
            ) : (
              <Flex vertical gap={10}>
                {title || getText('TEXT_DRAG_A_FILE_HERE_OR_UPLOAD_FULL')}
                {subtitle && <span>{subtitle}</span>}
              </Flex>
            )}
          </span>
        </React.Fragment>
      </Flex>
    </MyDefaultUpload>
  )
}

export default MyDefaultUploadWrapperBig
