import React from 'react'
import { Menu } from 'antd'
import Div from '../../Div/Div'
import './MyDefaultInsidePageLeftPanel.scss'

const MyDefaultInsidePageLeftPanel = ({
  selectedRoute,
  onClick,
  items,
  navigationMenuIsOnTop,
  forceSubMenuRender,
}) => {
  return (
    <Div className='my_default_inside_page_left_panel'>
      <Menu
        className='my_default_inside_page_left_panel--menu'
        mode='inline'
        selectedKeys={[selectedRoute]}
        inlineCollapsed={navigationMenuIsOnTop}
        onClick={onClick}
        items={items}
        forceSubMenuRender={forceSubMenuRender}
      />
    </Div>
  )
}

export default MyDefaultInsidePageLeftPanel
