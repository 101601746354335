import React, { useEffect } from 'react'
import { Form } from 'antd'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { locationSelector, userSelector } from '../../store/selectors/selectors'
import userActions from '../../store/modules/userActions'
import authActions from '../../store/modules/authActions'
import { getText, getTextServerError } from '../../lang'
import {
  checkAustralianPhoneNumber,
  checkFormatPhoneNumber,
  getInitialCountry,
} from '../../utils'
import IconOrganization from '../../icons/IconOrganization'
import MatForm from '../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../utils/Notify'
import { DEALER_SOCKET, PBS, ELEADS } from '../../config/constants'
import { useLayout } from '../../layout/LayoutProvider/LayoutProvider'
import { SUBMIT_BTN } from '../../devOptions'
import MyDefaultBlock from '../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultBlockRow from '../../components/MyDefaultBlock/MyDefaultBlockRow'
import MyDefaultPageLayout from '../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultInputText from '../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultInputEmail from '../../components/MyDefaultInputEmail/MyDefaultInputEmail'
import MyDefaultPhoneInput from '../../components/MyDefaultPhoneInput/MyDefaultPhoneInput'
import MyDefaultSelect from '../../components/MyDefaultSelect/MyDefaultSelect'

const ProfileEdit = () => {
  const { setPageTitle, setFooterButtons } = useLayout()
  const user = useSelector(userSelector)
  const location = useSelector(locationSelector)
  const navigate = useNavigate('')

  const [form] = Form.useForm()

  const language = Form.useWatch('language', form)

  useEffect(() => {
    setPageTitle(getText('WORD_PROFILE_INFORMATION'))
    setFooterButtons([
      {
        type: SUBMIT_BTN,
        action: () => {
          navigate('/profile/edit', { state: language })
        },
      },
    ])
  }, [user?.language, language])

  useEffect(() => {
    userActions.refreshUserFS().catch()
  }, [])

  const onFinish = async (values) => {
    if (!values?.phone && !values?.email) {
      notifyError(getText('ERR_NO_PHONE_OR_EMAIL'))
      return
    }

    if (values?.phone && values.phone.startsWith('04')) {
      values.phone = `61${values.phone.slice(1)}`
    }

    if (
      values.phone &&
      values?.phone.substring(0, 1) !== '1' &&
      values?.phone.substring(0, 2) !== '61'
    ) {
      notifyError(getTextServerError(getText('ERROR_WRONG_PHONE_FORMAT')))
      return
    }

    const obj = {
      isSuperAdmin: user?.isSuperAdmin,
      isAdmin: user?.isAdmin,
      isManager: user?.isManager,
      isSupportAgent: user?.isSupportAgent,
      _location_id: user?._location_id,
      enabled: user?.enabled,
      accessibleOrganizations: user?.accessibleOrganizations,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
      notifications: user?.notifications,
      language: values.language,
    }

    const notificationNames = [
      'unreadMessage',
      'assignToConversation',
      'unassignFromConversation',
      'paymentStatus',
    ]

    if (!values.phone) {
      notificationNames.forEach((notification) => {
        obj.notifications[notification].method = user?.notifications[
          notification
        ].method.filter((nt) => nt !== 'phone')

        if (obj.notifications[notification].method.length === 0) {
          obj.notifications[notification].enabled = false
        }
      })
    }

    if (!values.email) {
      notificationNames.forEach((notification) => {
        obj.notifications[notification].method = user?.notifications[
          notification
        ].method.filter((nt) => nt !== 'email')
        if (obj.notifications[notification].method.length === 0) {
          obj.notifications[notification].enabled = false
        }
      })
    }

    Object.assign(obj, {
      crmInfo: {
        dealerSocket: {
          username: values.dsUsername,
        },
        eLeads: {
          id: '',
          fullName: '',
          ...(user?.crmInfo.eLeads || {}),
        },
        pbs: {
          EmployeeRef: '',
          Name: '',
          Primary: false,
          Role: '',
          ...(user?.crmInfo.pbs || {}),
        },
        activix: {
          agent_id: '',
          ...(user?.crmInfo.activix || {}),
        },
      },
    })

    const result = await userActions.saveUpdate(obj, user?._id)
    if (result.success) {
      authActions.setUserData(result.data.user)
      if (getInitialCountry(user.phone) !== getInitialCountry(values.phone)) {
        form.setFieldValue('phone', result.data.user.phone)
      }
      notifySuccess(getText('NTF_SUCCESS_SAVE_PROFILE'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const onFinishFailed = ({ values }) => {
    if (
      !values.firstName ||
      values.firstName.match(/^ *$/) ||
      !values.lastName ||
      values.lastName.match(/^ *$/)
    ) {
      notifyError(getText('ERR_NO_NAME_OR_SURNAME'))
    }
  }

  const handleEmailChange = (e) => {
    let value = e.target.value
    if (value !== '' && form.getFieldError('phone').length) {
      form.validateFields()
    }
  }

  const RenderCrmInfo = () => {
    if (!user || !user?.crmInfo) {
      return <></>
    }

    const { dealerSocket, eLeads, pbs } = user?.crmInfo

    let userId = ''
    let userName = ''
    let userRole = ''

    const fieldsList = []

    switch (location?.crmType) {
      case DEALER_SOCKET: {
        userName = dealerSocket.username
        break
      }

      case ELEADS: {
        userId = eLeads && eLeads.id
        userName = eLeads && eLeads.fullName
        break
      }

      case PBS: {
        userId = pbs.EmployeeRef
        userName = pbs.Name
        userRole = pbs.Role
        break
      }
      default:
        break
    }

    if (userId) {
      fieldsList.push({
        icon: IconOrganization.organization,
        label: getText('WORD_USER_ID'),
        rightItem: userId,
      })
    }

    if (userName) {
      fieldsList.push({
        icon: IconOrganization.organization,
        label: getText('WORD_USER_NAME'),
        rightItem: userName,
      })
    }

    if (userRole) {
      fieldsList.push({
        icon: IconOrganization.organization,
        label: getText('WORD_USER_ROLE'),
        rightItem: userRole,
      })
    }

    return (
      (userId || userName || userRole) && (
        <MyDefaultBlock
          enableMarginTop
          title={getText('TEXT_SYNCHRONIZED_CRM_INFO')}
        >
          {fieldsList.map((field, index) => {
            return (
              <MyDefaultBlockRow
                key={field.label}
                isFirst={index === 0}
                label={field.label}
                value={field.rightItem}
                isLast={index === fieldsList.length - 1}
              />
            )
          })}
        </MyDefaultBlock>
      )
    )
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          firstName: user ? user?.firstName : '',
          lastName: user ? user?.lastName : '',
          email: user ? user?.email : '',
          phone: !_.isEmpty(user)
            ? user?.phone
              ? checkFormatPhoneNumber(user?.phone, true)
              : checkAustralianPhoneNumber(user?.phone)
            : '',
          dsUsername:
            (user &&
              user?.crmInfo &&
              user?.crmInfo?.dealerSocket &&
              user?.crmInfo?.dealerSocket.username) ||
            '',
          language: user ? user?.language : 'en',
        }}
      >
        <MyDefaultBlock>
          <MyDefaultInputText
            isForm
            name='firstName'
            label={getText('WORD_FIRST_NAME')}
            required
          />
          <MyDefaultInputText
            isForm
            name='lastName'
            label={getText('WORD_LAST_NAME')}
            required
          />
          <MyDefaultInputEmail
            isForm
            name='email'
            label={getText('WORD_EMAIL')}
            placeholder={getText('WORD_EMAIL')}
            onChange={handleEmailChange}
          />
          <MyDefaultPhoneInput
            isForm
            autoCompleteCountryCode
            initialCountry={getInitialCountry(user?.phone)}
            name='phone'
            label={getText('WORD_PHONE')}
            onChange={(value) => {
              form.setFieldsValue({ phone: value })
            }}
          />
          <MyDefaultSelect
            isForm
            name={'language'}
            label={getText('WORD_LANGUAGE')}
            options={[
              {
                value: 'en',
                label: getText('TEXT_ENGLISH'),
              },
              {
                value: 'fr',
                label: getText('TEXT_FRENCH'),
              },
            ]}
            showSearch={false}
            isLast={location?.crmType !== DEALER_SOCKET}
          />
          {location?.crmType === DEALER_SOCKET && (
            <MyDefaultInputText
              isForm
              isLast
              name='dsUsername'
              label={getText('WORD_DEALERSOCKET_USERNAME')}
            />
          )}
        </MyDefaultBlock>
        <RenderCrmInfo />
      </MatForm>
    </MyDefaultPageLayout>
  )
}

export default ProfileEdit
