import React, { useCallback } from 'react'
import { TreeSelect } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { getText } from '../../lang'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import './MyDefaultTreeSelect.scss'

const { SHOW_PARENT } = TreeSelect

const MyDefaultTreeSelect = ({
  required,
  disabled,
  message,
  treeData,
  isForm,
  label,
  name,
  hasFeedback,
  formStyle,
  initialValue,
  isLast,
  isFirst,
  fullWidth,
  color = 'grey',
  treeCheckable,
  allowClear = false,
  suffixIcon = false,
  placeholder = getText('WORD_SELECT_ITEM'),
  loading,
  filterOption,
  onChange,
  defaultValue,
  dropdownClassName,
  className,
}) => {
  const rules = useCallback(() => {
    const rules = []
    if (required && !disabled) {
      rules.push({ required: required, message: message })
    }
    return rules
  }, [required, disabled])

  const tagRender = (propsTag) => {
    const { label, value, onClose } = propsTag
    const selectedTag = treeData.find((item) => item.value === value)
    return (
      <span
        className='ant-select-selection-item'
        style={{
          backgroundColor: selectedTag && 'var(--mainColor)',
          color: selectedTag && 'var(--white)',
        }}
      >
        <span className='ant-select-selection-item-content'>{label}</span>
        <span className='ant-select-selection-item-remove' onClick={onClose}>
          <CloseOutlined style={{ color: selectedTag && 'var(--white)' }} />
        </span>
      </span>
    )
  }

  return isForm ? (
    <MyDefaultFormItem
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={rules()}
      style={formStyle}
      initialValue={initialValue}
      isLast={isLast}
      isFirst={isFirst}
    >
      <TreeSelect
        className={`my_default_select ${fullWidth ? 'full_width' : ''} ${color} ${className || ''}`}
        treeCheckable={treeCheckable}
        allowClear={allowClear}
        suffixIcon={suffixIcon}
        placeholder={placeholder}
        loading={loading}
        showCheckedStrategy={SHOW_PARENT}
        disabled={disabled}
        getPopupContainer={(trigger) => trigger.parentNode}
        filterTreeNode={
          Boolean(filterOption)
            ? filterOption
            : (input, option) => {
                return (
                  option.title
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toString().toLowerCase()) > -1
                )
              }
        }
        treeData={treeData}
        tagRender={tagRender}
        onChange={onChange}
        defaultValue={defaultValue}
        popupClassName={`my_default_select--dropdown ${dropdownClassName || ''}`}
      />
    </MyDefaultFormItem>
  ) : (
    <TreeSelect
      className={`my_default_select ${fullWidth ? 'full_width' : ''} ${color} ${className || ''}`}
      treeCheckable={treeCheckable}
      allowClear={allowClear}
      suffixIcon={suffixIcon}
      placeholder={placeholder}
      loading={loading}
      showCheckedStrategy={SHOW_PARENT}
      disabled={disabled}
      getPopupContainer={(trigger) => trigger.parentNode}
      filterTreeNode={
        Boolean(filterOption)
          ? filterOption
          : (input, option) => {
              return (
                option.title
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toString().toLowerCase()) > -1
              )
            }
      }
      treeData={treeData}
      tagRender={tagRender}
      onChange={onChange}
      defaultValue={defaultValue}
      popupClassName={`my_default_select--dropdown ${dropdownClassName || ''}`}
    />
  )
}

export default MyDefaultTreeSelect
