import React, { memo } from 'react'
import { Layout } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { getText } from '../../lang'
import MyDefaultButton from '../../components/MyDefaultButton/MyDefaultButton'
import { useLayout } from '../LayoutProvider/LayoutProvider'
import { CANCEL_BTN, SUBMIT_BTN } from '../../devOptions'
import './MainFooter.scss'

const { Footer } = Layout

const MainFooter = () => {
  const { footerButtons, loading } = useLayout()

  const location = useLocation()
  const navigate = useNavigate()
  const handleCancel = () => {
    const currentPath = location.pathname
    if (currentPath.includes('location/settings')) {
      if (
        currentPath.includes('livechat') &&
        (currentPath.includes('edit') || currentPath.includes('add'))
      ) {
        // example : livechat-announcement/special/edit
        // OR livechat-greetings/add
        const indexOfLastTrail = currentPath.lastIndexOf('/')
        return navigate(currentPath.substring(0, indexOfLastTrail))
      }
      return navigate('/location/list')
    }
    navigate(-1)
  }

  const getButtonType = (type) => {
    switch (type) {
      case CANCEL_BTN:
        return 'cancel'
      case SUBMIT_BTN:
        return 'primary'
      default:
        return 'primary'
    }
  }

  const getButtonTitle = (button) => {
    if (button?.title) {
      return button.title
    }

    switch (button.type) {
      case CANCEL_BTN:
        return getText('ACTION_CANCEL')
      case SUBMIT_BTN:
        return getText('ACTION_SAVE_CHANGES')
      default:
        return getText('ACTION_SAVE_CHANGES')
    }
  }

  const getButtonAction = (button) => {
    if (button?.action) {
      return button.action()
    }

    switch (button.type) {
      case CANCEL_BTN:
        return handleCancel()
      case SUBMIT_BTN:
        return null
      default:
        return null
    }
  }

  const getHtmlType = (button) => {
    if (button?.htmlType) {
      return button.htmlType
    }

    switch (button.type) {
      case CANCEL_BTN:
        return 'button'
      case SUBMIT_BTN:
        return 'submit'
      default:
        return 'submit'
    }
  }

  return (
    <Footer className='mat-footer'>
      {(footerButtons || []).map((button, index) => {
        return (
          <MyDefaultButton
            key={index}
            buttonText={getButtonTitle(button)}
            typeButton={getButtonType(button?.type)}
            htmlType={getHtmlType(button)}
            onClick={() => {
              getButtonAction(button)
            }}
            minWidth={161}
            loading={button.type === SUBMIT_BTN && loading}
          />
        )
      })}
    </Footer>
  )
}

export default memo(MainFooter)
