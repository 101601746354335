import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import authActions from '../../../store/modules/authActions'
import { getText } from '../../../lang'
import { moveToLoginPage, myPasswordValidator } from '../../../utils'
import SVGLoginLogo from '../../../icons/SVG/SVGLoginLogo'
import LoginPageIcon from '../../../icons/login-page-icon.png'
import MatForm from '../../../components/Form/MatForm'
import PageFull from '../../../components/PageFull'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import MatRow from '../../../components/MatRow'
import MatButton from '../../../components/MatButton'
import InputFormPasswordSecondary from '../../../components/Form/InputFormPassword/InputFormPasswordSecondary'
import InputFormPasswordConfirmSecondary from '../../../components/Form/InputFormPasswordConfirm/InputFormPasswordConfirmSecondary'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import LoginPageHeader from './LoginPageHeader'

const PasswordReset = () => {
  const { isMobileOrTabletOrTabletPortrait } = useDeviceCheck()
  const [loading, setLoading] = useState(false)

  const urlParams = new URLSearchParams(window.location.search.replace(/\+/g, '%2B'))
  const navigate = useNavigate()
  const location = useLocation()
  const token = urlParams.get('token') || ''
  const email = urlParams.get('email') || ''
  const phone = urlParams.get('phone') || ''

  const [form] = Form.useForm()

  useEffect(() => {
    const fill = async () => {
      if (location.state && location.state.token === 'loginWithTemporaryPassword') {
        return
      }

      if (token && (email || phone)) {
        const result = await authActions.resetPasswordCheck(email, phone, token)
        if (!result.success) {
          notifyError(
            result.errMsg
              ? result.errMsg.startsWith('child')
                ? getText('ERR_GENERAL')
                : result.errMsg
              : getText('ERR_GENERAL')
          )
          moveToLoginPage(navigate)
        }
      } else {
        moveToLoginPage(navigate)
      }
    }
    fill()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    let result

    if (
      location &&
      location.state &&
      location.state.token === 'loginWithTemporaryPassword'
    ) {
      result = await authActions.setPassword(
        values.newPassword,
        values.confirmPassword
      )
    } else {
      result = await authActions.resetPassword(
        email,
        phone,
        token,
        values.newPassword
      )
    }

    if (result.success) {
      notifySuccess(getText('NTF_SUCCESS_RESET_PASSWORD'))
      moveToLoginPage(navigate)
    } else if (result.errMsg) {
      notifyError(
        result.errMsg
          ? result.errMsg.startsWith('child')
            ? getText('ERR_GENERAL')
            : result.errMsg
          : getText('ERR_GENERAL')
      )
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <PageFull className='login-page-wrapper'>
      <Row className='login-page-main-row'>
        <Col
          className='login-page-main-col'
          style={{
            width: !isMobileOrTabletOrTabletPortrait ? '59%' : '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: !isMobileOrTabletOrTabletPortrait
                ? 'flex-start'
                : 'center',
              marginTop: !isMobileOrTabletOrTabletPortrait ? 0 : 20,
            }}
          >
            <SVGLoginLogo />
          </div>
          <Card bordered={false} className='login-page-left-card'>
            <LoginPageHeader
              customTitle={getText('WORD_NEW_PASSWORD')}
              noCustomText
            />
            <MatForm
              form={form}
              name='formSaveResetPassword'
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <InputFormPasswordSecondary
                name='newPassword'
                label={getText('WORD_NEW_PASSWORD')}
                autoComplete='new-password'
                required
                additionalRules={[
                  {
                    validator: myPasswordValidator,
                  },
                ]}
              />
              <InputFormPasswordConfirmSecondary
                name='confirmPassword'
                label={getText('WORD_CONFIRM_PASSWORD')}
                passwordToConfirm='newPassword'
                required
              />
              <MatRow className='login-page-button-wrapper'>
                <MatButton
                  buttonText={getText('ACTION_CANCEL')}
                  htmlType={''}
                  typeButton={'white'}
                  onClick={() => {
                    navigate(-1)
                  }}
                  className='magic-link-btn'
                />
                <MatButton
                  loading={loading}
                  formName={'formSaveResetPassword'}
                  buttonText={getText('ACTION_SAVE')}
                  htmlType={'submit'}
                  className='login-link-btn'
                />
              </MatRow>
            </MatForm>
          </Card>
        </Col>
        {!isMobileOrTabletOrTabletPortrait ? (
          <div className='login-page-right-card'>
            <img src={LoginPageIcon} alt='' />
          </div>
        ) : null}
      </Row>
    </PageFull>
  )
}

export default PasswordReset
