import React, { useEffect } from 'react'
import { Form } from 'antd'
import authActions from '../../store/modules/authActions'
import { getText, getTextServerError } from '../../lang'
import { myPasswordValidator } from '../../utils'
import MatForm from '../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../utils/Notify'
import { useLayout } from '../../layout/LayoutProvider/LayoutProvider'
import { SUBMIT_BTN } from '../../devOptions'
import MyDefaultPageLayout from '../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultInputPassword from '../../components/MyDefaultInputPassword/MyDefaultInputPassword'
import MyDefaultBlock from '../../components/MyDefaultBlock/MyDefaultBlock'

const ProfileChangePassword = () => {
  const { setPageTitle, setFooterButtons } = useLayout()
  const [form] = Form.useForm()

  useEffect(() => {
    setPageTitle(getText('TEXT_PROFILE_CHANGE_PASSWORD'))
    setFooterButtons([
      {
        type: SUBMIT_BTN,
      },
    ])
  }, [])

  const onFinish = async (values) => {
    const result = await authActions.setPassword(
      values.newPassword,
      values.confirmPassword
    )
    if (result.success) {
      if (result.data.token) {
        authActions.storeAuthData(
          result.data.user,
          result.data.token,
          authActions.getUnreadMessages(),
          authActions.getMyMessagesInboxUnreadMessages()
        )
      }
      notifySuccess(getText('NTF_SUCCESS_SAVE_PASSWORD'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          newPassword: '',
          confirmPassword: '',
        }}
      >
        <MyDefaultBlock>
          <MyDefaultInputPassword
            isForm
            name='newPassword'
            label={getText('WORD_NEW_PASSWORD')}
            errorMessage={`${getText('MSG_PLEASE_INPUT_PASSWORD')}!`}
            autoComplete='new-password'
            required
            additionalRules={[
              {
                validator: myPasswordValidator,
              },
            ]}
          />
          <MyDefaultInputPassword
            isForm
            name='confirmPassword'
            label={getText('WORD_CONFIRM_PASSWORD')}
            errorMessage={`${getText('MSG_PLEASE_CONFIRM_YOUR_PASSWORD')}!`}
            passwordToConfirm='newPassword'
            required
            isLast
          />
        </MyDefaultBlock>
      </MatForm>
    </MyDefaultPageLayout>
  )
}

export default ProfileChangePassword
