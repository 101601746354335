import React from 'react'
import { getText } from '../../../lang'
import MyDefaultCheckbox from '../../../components/MyDefaultCheckbox/MyDefaultCheckbox'
import MyDefaultTreeSelect from '../../../components/MyDefaultTreeSelect/MyDefaultTreeSelect'
import MyDefaultSelect from '../../../components/MyDefaultSelect/MyDefaultSelect'
import MyDefaultSendFollowUp from '../../../components/MyDefaultSendFollowUp/MyDefaultSendFollowUp'

const CollapseChildren = ({
  getListOption,
  isCheckBox,
  collapseKey,
  onCheckBoxChange,
  data,
  isCheckBoxEnabled,
  isLoading,
  checkBoxValue,
  isTree,
  hasOnlyOrganizations,
  onLoadMore,
  isInputNumber,
  showSingleAlways,
  singleDefaultOptions,
  isNotificationSelectEnabled,
}) => {
  const itemDetails = {
    unreadMessage: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      treeSelectName: ['notifications', 'unreadMessage', 'organizations'],
      treeSelectLabel: getText(
        'TEXT_WHAT_LOCATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      treeSelectPlaceholder: getText('WORD_SELECT_LOCATIONS'),
      treeSelectMessage: getText('TEXT_AT_LEAST_ONE_LOCATION_MUST_BE_SELECTED'),
      notificationSelectPlaceholder: getText('WORD_SELECT_TYPE'),
      notificationSelectName: ['notifications', 'unreadMessage', 'method'],
      notificationSelectLabel: getText(
        'LABEL_HOW_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS'
      ),
      notificationSelectMessage: getText('ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION'),
      inputName: ['notifications', 'unreadMessage', 'period'],
      inputTitle: getText(
        'TEXT_RECEIVE_NOTIFICATION_IF_THE_CONVERSATION_IS_UNREAD_FOR'
      ),
      inputErrorMessage: getText('TEXT_PLEASE_ENTER_THE_PERIOD_IN_MINUTES'),
      inputText: getText('WORD_MINUTES'),
      min: 0,
    },

    newsletterReports: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      organizationSelectName: [
        'notifications',
        'newsletterReports',
        'organizations',
      ],
      organizationSelectLabel: getText(
        'TEXT_WHAT_ORGANIZATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      organizationSelectPlaceholder: getText('WORD_SELECT_ORGANIZATIONS'),
      organizationSelectMessage: getText(
        'TEXT_AT_LEAST_ONE_ORGANIZATION_MUST_BE_SELECTED'
      ),
      notificationSelectPlaceholder: getText('WORD_SELECT_TYPE'),
      notificationSelectName: ['notifications', 'newsletterReports', 'method'],
      notificationSelectLabel: getText('TEXT_WHAT_REPORTS_DO_YOU_WANT_TO_RECEIVE'),
      notificationSelectMessage: getText(
        'TEXT_AT_LEAST_ONE_OPTION_MUST_BE_SELECTED'
      ),
    },

    assignToConversation: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      organizationSelectName: [
        'notifications',
        'assignToConversation',
        'organizations',
      ],
      organizationSelectLabel: getText(
        'TEXT_WHAT_ORGANIZATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      organizationSelectPlaceholder: getText('WORD_SELECT_ORGANIZATIONS'),
      organizationSelectMessage: getText(
        'TEXT_AT_LEAST_ONE_ORGANIZATION_MUST_BE_SELECTED'
      ),

      notificationSelectPlaceholder: getText('WORD_SELECT_TYPE'),
      notificationSelectName: ['notifications', 'assignToConversation', 'method'],
      notificationSelectLabel: getText(
        'LABEL_HOW_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS'
      ),
      notificationSelectMessage: getText('ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION'),
    },

    taggedInConversation: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      organizationSelectName: [
        'notifications',
        'taggedInConversation',
        'organizations',
      ],
      organizationSelectLabel: getText(
        'TEXT_WHAT_ORGANIZATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      organizationSelectPlaceholder: getText('WORD_SELECT_ORGANIZATIONS'),
      organizationSelectMessage: getText(
        'TEXT_AT_LEAST_ONE_ORGANIZATION_MUST_BE_SELECTED'
      ),
      notificationSelectPlaceholder: getText('WORD_SELECT_TYPE'),
      notificationSelectName: ['notifications', 'taggedInConversation', 'method'],
      notificationSelectLabel: getText(
        'LABEL_HOW_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS'
      ),
      notificationSelectMessage: getText('ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION'),
    },

    unassignFromConversation: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      treeSelectName: ['notifications', 'unassignFromConversation', 'organizations'],
      treeSelectLabel: getText(
        'TEXT_WHAT_LOCATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      treeSelectPlaceholder: getText('WORD_SELECT_LOCATIONS'),
      treeSelectMessage: getText('TEXT_AT_LEAST_ONE_LOCATION_MUST_BE_SELECTED'),
      notificationSelectPlaceholder: getText('WORD_SELECT_TYPE'),
      notificationSelectName: [
        'notifications',
        'unassignFromConversation',
        'method',
      ],
      notificationSelectLabel: getText(
        'LABEL_HOW_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS'
      ),
      notificationSelectMessage: getText('ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION'),
      inputName: ['notifications', 'unassignFromConversation', 'period'],
      inputTitle: getText(
        'TEXT_RECEIVE_NOTIFICATION_IF_THE_CONVERSATION_IS_UNREAD_FOR'
      ),
      inputErrorMessage: getText('TEXT_PLEASE_ENTER_THE_PERIOD_IN_MINUTES'),
      inputText: getText('WORD_MINUTES'),
    },
    paymentStatus: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      organizationSelectName: ['notifications', 'paymentStatus', 'organizations'],
      organizationSelectLabel: getText(
        'TEXT_WHAT_ORGANIZATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      organizationSelectPlaceholder: getText('WORD_SELECT_ORGANIZATIONS'),
      organizationSelectMessage: getText(
        'TEXT_AT_LEAST_ONE_ORGANIZATION_MUST_BE_SELECTED'
      ),
      notificationSelectPlaceholder: getText('WORD_SELECT_TYPE'),
      notificationSelectName: ['notifications', 'paymentStatus', 'method'],
      notificationSelectLabel: getText(
        'LABEL_HOW_DO_YOU_WANT_TO_RECEIVE_NOTIFICATIONS'
      ),
      notificationSelectMessage: getText('ERROR_PLEASE_CHOOSE_TYPE_OF_NOTIFICATION'),
    },
    unhappyCustomer: {
      checkBoxLabel: getText('TEXT_RECEIVE_NOTIFICATIONS_FROM_ALL_ORGANIZATIONS'),
      treeSelectName: ['notifications', 'unhappyCustomer', 'organizations'],
      treeSelectLabel: getText(
        'TEXT_WHAT_LOCATIONS_DO_YOU_WANT_RECEIVE_NOTIFICATIONS_FROM'
      ),
      treeSelectPlaceholder: getText('WORD_SELECT_LOCATIONS'),
      treeSelectMessage: getText('TEXT_AT_LEAST_ONE_LOCATION_MUST_BE_SELECTED'),
    },
  }

  return (
    <React.Fragment>
      {isCheckBox && (
        <React.Fragment>
          {data.list.length > 1 && (
            <MyDefaultCheckbox
              isForm
              title={itemDetails[collapseKey].checkBoxLabel}
              disabled={!isCheckBoxEnabled}
              onChange={onCheckBoxChange}
              value={checkBoxValue}
            />
          )}
          {((!checkBoxValue && (isTree || hasOnlyOrganizations)) ||
            showSingleAlways) &&
            (isTree ? (
              <MyDefaultTreeSelect
                isForm
                loading={isLoading}
                name={itemDetails[collapseKey].treeSelectName}
                label={itemDetails[collapseKey].treeSelectLabel}
                treeData={data.list || []}
                treeCheckable={true}
                placeholder={itemDetails[collapseKey].treeSelectPlaceholder}
                message={itemDetails[collapseKey].treeSelectMessage}
                required={isCheckBoxEnabled}
                disabled={!isCheckBoxEnabled}
                isLast={!isNotificationSelectEnabled && !isInputNumber}
              />
            ) : hasOnlyOrganizations ? (
              <MyDefaultSelect
                isForm
                loading={isLoading}
                name={itemDetails[collapseKey].organizationSelectName}
                label={itemDetails[collapseKey].organizationSelectLabel}
                placeholder={itemDetails[collapseKey].organizationSelectPlaceholder}
                options={
                  data.list && data.list.length
                    ? data.list
                    : singleDefaultOptions || []
                }
                onLoadMore={onLoadMore}
                message={itemDetails[collapseKey].organizationSelectMessage}
                mode='multiple'
                required={isCheckBoxEnabled}
                disabled={!isCheckBoxEnabled}
                isLast={!isNotificationSelectEnabled && !isInputNumber}
              />
            ) : null)}
        </React.Fragment>
      )}
      {Boolean(isNotificationSelectEnabled) && (
        <MyDefaultSelect
          isForm
          placeholder={itemDetails[collapseKey].notificationSelectPlaceholder}
          name={itemDetails[collapseKey].notificationSelectName}
          label={itemDetails[collapseKey].notificationSelectLabel}
          options={getListOption && getListOption()}
          mode='multiple'
          required={isCheckBoxEnabled}
          disabled={!isCheckBoxEnabled}
          showSearch={false}
          message={itemDetails[collapseKey].notificationSelectMessage}
          isLast={!isInputNumber}
        />
      )}
      {Boolean(isInputNumber) && (
        <MyDefaultSendFollowUp
          isForm
          inputName={itemDetails[collapseKey].inputName}
          beforeText={itemDetails[collapseKey].inputTitle}
          afterText={itemDetails[collapseKey].inputText}
          hideSelect
          title={''}
          required={isCheckBoxEnabled}
          disabled={!isCheckBoxEnabled}
          errorMessage={itemDetails[collapseKey].inputErrorMessage}
          min={itemDetails[collapseKey].min ?? 1}
        />
      )}
    </React.Fragment>
  )
}

export default CollapseChildren
