import React, { useState } from 'react'
import { Form, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import { urlServer } from '../../../../utils/UrlHelper'
import nylasActions from '../../../../store/modules/nylasActions'
import authActions from '../../../../store/modules/authActions'
import { getText } from '../../../../lang'
import InputFormEmail from '../../../../components/Form/InputFormEmail'
import MatForm from '../../../../components/Form/MatForm'
import MatModal from '../../../../components/MatModal'
import MatRow from '../../../../components/MatRow'
import MatButton from '../../../../components/MatButton'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import FeaturesConnectingData from '../../components/FeaturesConnectingData'
import { userSelector } from '../../../../store/selectors/selectors'

const HandleNylasConnection = (props) => {
  const { location } = props

  const user = useSelector(userSelector)

  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  const hasNylasAccount =
    location.integrationInfo &&
    location.integrationInfo.nylas.email_address &&
    location.integrationInfo.nylas.provider

  const onFinish = async (emailObj) => {
    setIsLoading(true)

    const response = await nylasActions.connectNylasAccountForOrganization(
      location?._id,
      emailObj
    )
    if (response.success) {
      const redirectUrl = response.data.replace('{{api_url}}', urlServer())
      await authActions.refreshOrganization()
      // setTimeout for safari
      setTimeout(() => {
        window.open(redirectUrl, '_blank')
      }, 100)
    } else {
      notifyError(response.errMsg || 'Email connection redirect link error')
    }
    setIsLoading(false)
    setVisible(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const disconnectNylasAccount = async () => {
    try {
      const response = await nylasActions.disconnectNylasAccountForOrganization(
        location?._id
      )
      if (response) {
        notifySuccess(getText('TEXT_YOU_SUCCESSFULLY_DISCONNECTED_YOUR_EMAIL'))
        location.featuresConnectingData.nylas = response.featuresConnectingData.nylas
        location.integrationInfo.nylas = {}
        await authActions.refreshOrganization()
      }
    } catch (e) {
      notifyError(
        `${getText('TEXT_EMAIL_DISCONNECT_ERROR')} ${JSON.stringify(e, null, 2)}`
      )
    }
  }

  return (
    <MatBlockWithLabel className='integration-item-footer-item integration-item-footer-item-connect-email'>
      <FeaturesConnectingData
        featuresConnectingData={
          location.featuresConnectingData && location.featuresConnectingData.nylas
        }
      />
      {hasNylasAccount ? (
        <>
          <div className='footer-connected-account'>
            <span>{getText('WORD_CONNECTED_EMAIL')}</span>
            <span>{location.integrationInfo.nylas.email_address}</span>
          </div>
          {!Boolean(user.isSuperAdmin && user.isAdmin) ? (
            <Tooltip
              placement='top'
              title={getText('TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR')}
            >
              <MatButton
                buttonText={getText('WORD_DISCONNECT')}
                htmlType='button'
                style={{ maxWidth: '190px', cursor: 'not-allowed' }}
                typeButton={'red'}
              />
            </Tooltip>
          ) : (
            <ConfirmPopup
              title={`${getText(
                'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_EMAIL'
              )}: ${location.integrationInfo.nylas.email_address}?`}
              okText={getText('WORD_DISCONNECT')}
              onConfirm={disconnectNylasAccount}
              cancelText={getText('WORD_NO')}
              trigger={
                <MatButton
                  buttonText={getText('WORD_DISCONNECT')}
                  htmlType='button'
                  style={{ maxWidth: '190px' }}
                  typeButton={'red'}
                />
              }
            />
          )}
        </>
      ) : (
        <>
          <div />
          <MatModal
            title={getText('TEXT_CONNECT_YOUR_EMAIL')}
            formName='formSaveOrganization'
            trigger={
              <MatButton
                buttonText={getText('WORD_CONNECT')}
                htmlType='button'
                style={{ maxWidth: '190px' }}
                typeButton={'red'}
              />
            }
            confirmLoading={isLoading}
            visible={visible}
            onTrrigerClick={() => {
              setVisible(true)
              form.resetFields()
            }}
            onCancel={() => setVisible(false)}
            okText={getText('WORD_CONNECT')}
          >
            <MatForm
              form={form}
              name='formSaveOrganization'
              initialValues={{
                email: location.integrationInfo.nylas.email_address,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <MatRow>
                <InputFormEmail
                  name='email'
                  label={getText('TEXT_PLEASE_ENTER_THE_EMAIL_YOU_WANT_TO_CONNECT')}
                  required={false}
                  placeholder={getText('WORD_EMAIL')}
                />
              </MatRow>
            </MatForm>
          </MatModal>
        </>
      )}
    </MatBlockWithLabel>
  )
}

export default HandleNylasConnection
