import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  memo,
  useMemo,
} from 'react'
import * as moment from 'moment'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import dayjs from 'dayjs'
import SVGCalls from '../../../../icons/SVG/SVGCalls'
import SVGChat from '../../../../icons/SVG/SVGChat'
import SVGFacebook from '../../../../icons/SVG/SVGFacebook'
import SVGInstagram from '../../../../icons/SVG/SVGInstagram'
import SVGMessageCenterNoData from '../../../../icons/SVG/SVGMessageCenterNoData'
import SVGOrganic from '../../../../icons/SVG/SVGOrganic'
import messageActions from '../../../../store/modules/messageActions'
import ListLoadMoreTop from '../../../../components/ListLoadMoreTop'
import {
  addTagBeforeText,
  getMobileOperatingSystem,
  MainColor,
  myWebViewPost,
} from '../../../../utils'
import SVGPushToCRMIcon from '../../../../icons/SVG/SVGPushToCRMIcon'
import {
  CALL,
  CONSENT_EDIT,
  OUTGOING_CALL,
  REMINDER_BOT,
  OUTGOING_CALL_LINK,
  PAYMENT,
  ASSIGN,
  ARCHIVE_STATUS,
  MATADOR_CONNECT,
  NOTE,
  HOUR,
  FACEBOOK,
  INSTAGRAM,
  ORGANIC,
  CALLS,
  EMAIL,
  REMINDER,
  EXPORT,
  REJECTED,
  TAG_USER,
  RECEIVED,
  SUBSCRIBE,
  UNSUBSCRIBE,
  VOICE_OUTGOING_CALL_FINISHED,
  VOICE_OUTGOING_CALL_INITIATED,
  SENT,
  BOT,
  AI_REPLY,
  SKIP_SCHEDULED_MESSAGE,
  AUTO_REPLY,
  PHONE,
  BOT_CANCELLED,
  REACTIVATE,
  SNOOZE,
  CONVERSATION_CREATED,
  DELETED,
  UPDATED,
  UNASSIGNED,
  GENERATED,
  VOICE_AI,
  SEQUENCE,
  SUPERHUMAN_CHAT_SESSION,
  OUTBOUND_VOICE_AI,
  APPOINTMENT,
} from '../../utils/constants'
import SVGMessageSubscribeIcon from '../../../../icons/SVG/SVGMessageSubscribeIcon'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import { getText, getTextServerError } from '../../../../lang'
import SVGMessageSendLaterIcon from '../../../../icons/SVG/SVGMessageSendLaterIcon'
import { FB_MESSANGER, IG_MESSANGER, RED, SMS } from '../../../../devOptions'
import SVGMessageRemindMeIcon from '../../../../icons/SVG/SVGMessageRemindMeIcon'
import SVGPlayIcon from '../../../../icons/SVG/SVGPlayIcon'
import paymentsActions from '../../../../store/modules/paymentsAction'
import SVGMessageSkippedIcon from '../../../../icons/SVG/SVGMessageSkippedIcon'
import SVGMessageUnsubscribeIcon from '../../../../icons/SVG/SVGMessageUnsubscribeIcon'
import customerActions from '../../../../store/modules/customerActions'
import authActions from '../../../../store/modules/authActions'
import CenterInvitation from '../centerMessageBody/CenterMessageItems/CenterInvitation'
import CenterOther from '../centerMessageBody/CenterMessageItems/CenterOther'
import CenterPayment from '../centerMessageBody/CenterPayment/CenterPayment'
import MessageCenterFooter from '../centerMessageFooter/MessageFooterMain/MessageCenterFooter'
import MessageCenterHeader from '../centerMessageHeader/MessageCenterMain/MessageCenterHeader'
import CenterOnline from '../centerMessageBody/CenterMessageOnline/CenterOnline'
import CenterEmail from '../centerMessageBody/CenterMessageItems/CenterEmail'
import CenterVideo from '../centerMessageBody/CenterMessageItems/CenterVideo'
import CenterMessageItem from '../centerMessageBody/CenterMessage/CenterMessageItem'
import CenterSkipScheduledMessage from '../centerMessageBody/CenterMessageDripSequence/CenterSkipScheduledMessage'
import CenterMessageSuperhumanSnooze from '../centerMessageBody/CenterMessageSuperhumanSnooze/CenterMessageSuperhumanSnooze'
import SVGMessageUnskippedIcon from '../../../../icons/SVG/SVGMessageUnskippedIcon'
import organizationActions from '../../../../store/modules/organizationActions'
import userActions from '../../../../store/modules/userActions'
import SVGConversationCreated from '../../../../icons/SVG/SVGConversationCreated'
import SVGMessageAssignIcon from '../../../../icons/SVG/SVGMessageAssignIcon'
import SVGMessageAppointmentCreateIcon from '../../../../icons/SVG/SVGMessageAppointmentCreateIcon'
import SVGMessageIncomingStartIcon from '../../../../icons/SVG/SVGMessageIncomingStartIcon'
import SVGMessageIncomingFinishedIcon from '../../../../icons/SVG/SVGMessageIncomingFinishedIcon'
import SVGMessageAppointmentUpdatedIcon from '../../../../icons/SVG/SVGMessageAppointmentUpdatedIcon'
import SVGMessageAppointmentCanceledIcon from '../../../../icons/SVG/SVGMessageAppointmentCanceledIcon'
import SVGMessageJoinCallIcon from '../../../../icons/SVG/SVGMessageJoinCallIcon'
import SVGMessageConsentIcon from '../../../../icons/SVG/SVGMessageConsentIcon'
import SVGMessageUnsssignIcon from '../../../../icons/SVG/SVGMessageUnsssignIcon'
import SVGMessageNoteIcon from '../../../../icons/SVG/SVGMessageNoteIcon'
import {
  isMobileAppSelector,
  organizationSelector,
  orgEnablePaymentsSelector,
  userSelector,
} from '../../../../store/selectors/selectors'
import SVGAISummaryIcon from '../../../../icons/SVG/SVGAISummaryIcon'
import '../centerMessageBody/CenterMessageItems/CenterMessageItems.scss'
import CenterVoiceAIBlock from '../centerMessageBody/CenterMessage/CenterVoiceAIBlock'
import SVGVoiceAIMessagesIcon from '../../../../icons/SVG/SVGVoiceAIMessagesIcon'
import './MessageCenter.scss'
import CenterAIBChatBlock from '../centerMessageBody/CenterMessage/CenterAIBChatBlock'
import SVGConversationIcon from '../../../../icons/SVG/SVGConversationIcon'

const MessageCenter = forwardRef((props, ref) => {
  const {
    conversation,
    refreshConversation,
    changeMessageType,
    messageType,
    onChangeEmailSMSType,
    msgChangeEmailSMSType,
    removeConversationFromList,
    showRightBox,
    history,
    closeCenterDrawer,
    isExtension,
    isBlueExtension,
    additionalHeader,
    extensionQuery,
  } = props

  let organizationPayments = useSelector(orgEnablePaymentsSelector)
  let organization = useSelector(organizationSelector)
  let user = useSelector(userSelector)
  let isMobileApp = useSelector(isMobileAppSelector)

  const [listMessages, setlistMessages] = useState([])
  const [page, setPage] = useState(0)
  const [perPage] = useState(20)
  const [totalCount, setTotalCount] = useState(0)
  const [loadingData, setloadingData] = useState(false)

  const centerScroll = useRef()
  const connectFooter = useRef()

  const [isEmpty, setisEmpty] = useState(false)
  const [isMessageScheduled, setisMessageScheduled] = useState(false)
  const [refreshMessage, setRefreshMessage] = useState(false)
  const [draftedMessage, setDraftedMessage] = useState({
    message: '',
    media: [],
    userIds: [],
    subMessage: '',
  })
  const [nextScheduledMessages, setNextScheduledMessages] = useState([])
  const [loadingNextScheduledMessage, setLoadingNextScheduledMessage] =
    useState(false)
  const [smsTemplatesList, setsmsTemplatesList] = useState([])
  const [emailTemplatesList, setemailTemplatesList] = useState([])
  const [usersTagList, setusersTagList] = useState([])
  const [getLastNotSentMessage, setGetLastNotSentMessage] = useState('')

  const nextScheduledMessageEnabled =
    organization?.restrictions?.allowNextScheduledSequenceMessage?.enabled

  useEffect(() => {
    setloadingData(true)
    setTimeout(() => {
      setPage(0)
      setTotalCount(0)
      setlistMessages([])
    }, 150)
    setDraftedMessage({
      message: '',
      media: [],
      userIds: [],
      subMessage: '',
    })
    setNextScheduledMessages([])
  }, [conversation._id])

  const findLastEmailSentMessage = (list) => {
    const unusedItems = [ASSIGN, SKIP_SCHEDULED_MESSAGE, TAG_USER]
    let lastEmailIndex = null
    let lastIndex = null

    for (let i = 0; i < list.length; i++) {
      const currentItem = list[i]
      if (unusedItems.includes(currentItem.type)) {
        continue
      } else if (currentItem.sentBy === EMAIL) {
        lastEmailIndex = i
      } else {
        lastIndex = i
      }
    }

    if (lastEmailIndex !== null && lastIndex > lastEmailIndex) {
      return null
    }

    return lastEmailIndex !== null ? list[lastEmailIndex] : null
  }

  useEffect(() => {
    let isMounted = true
    const fill = async () => {
      if (conversation) {
        let resultMessages
        if (conversation.isNew) {
          resultMessages = { success: false }
          if (
            (conversation?.receiver?.phone && extensionQuery?.type !== EMAIL) ||
            conversation?.receiver?.email
          ) {
            onChangeEmailSMSType &&
              onChangeEmailSMSType(
                extensionQuery?.type === EMAIL && conversation?.receiver?.email
                  ? EMAIL
                  : PHONE
              )
          }
        } else {
          resultMessages = await messageActions.getConversationMessagesList(
            page,
            perPage,
            conversation._id,
            conversation
          )
        }
        if (isMounted) {
          setloadingData(false)
          if (resultMessages.success) {
            setDraftedMessage(resultMessages.data.find((item) => item.isDraft))
            setNextScheduledMessages(
              resultMessages.data.filter(
                (item) =>
                  item.type === AUTO_REPLY || item.exists || item.type === GENERATED
              )
            )
            let list = resultMessages.data.filter(
              (item) =>
                item.type !== REMINDER &&
                item.status !== REJECTED &&
                !item.isDraft &&
                item.type !== AUTO_REPLY &&
                !item.exists &&
                item.type !== GENERATED
            )
            if (page > 0) {
              if (resultMessages.data && resultMessages.data.length) {
                list = list.concat(listMessages)
                setTimeout(() => {
                  centerScroll.current.changeScrollTopPosition(500)
                }, 100)
                setlistMessages(list)
                setTotalCount(resultMessages.max)
              } else {
                setTotalCount(list.length)
              }
            }
            if (page === 0) {
              setlistMessages(list)
              setTotalCount(resultMessages.max)
              scrollToBottom()
              if (list && list.length > 0) {
                let lastMsg = list[list.length - 1]
                const lastEmail = findLastEmailSentMessage(list)
                if (
                  ((lastMsg && lastMsg.sentBy === EMAIL) ||
                    extensionQuery?.type === EMAIL) &&
                  conversation.receiver.email
                ) {
                  onChangeEmailSMSType && onChangeEmailSMSType(EMAIL)
                } else if (
                  (Boolean(lastEmail) || extensionQuery?.type === EMAIL) &&
                  conversation.receiver.email
                ) {
                  onChangeEmailSMSType && onChangeEmailSMSType(EMAIL)
                } else if (
                  conversation.receiver &&
                  ((conversation.receiver.phone === '' &&
                    conversation.receiver.email) ||
                    (conversation.receiver.status.state === RED &&
                      conversation.receiver.phone &&
                      conversation.receiver.email))
                ) {
                  onChangeEmailSMSType && onChangeEmailSMSType(EMAIL)
                } else if (
                  conversation.last_source === FB_MESSANGER ||
                  conversation.last_source === IG_MESSANGER
                ) {
                  onChangeEmailSMSType &&
                    onChangeEmailSMSType(conversation.last_source)
                } else {
                  onChangeEmailSMSType && onChangeEmailSMSType(PHONE)
                }
              }
            }
            setRefreshMessage(false)
          } else {
            setlistMessages([])
            setTotalCount(0)
          }
        }
      } else {
        setisEmpty(true)
      }
    }
    fill()
    return () => {
      isMounted = false
    }
  }, [page, perPage, conversation._id, refreshMessage])

  const getIconSource = (source) => {
    switch (source) {
      case FACEBOOK:
        return <SVGFacebook style={{ marginRight: '10px' }} width={20} height={20} />
      case INSTAGRAM:
        return (
          <SVGInstagram style={{ marginRight: '10px' }} width={20} height={20} />
        )
      case ORGANIC:
        return <SVGOrganic style={{ marginRight: '10px' }} width={20} height={20} />
      case CALLS:
        return <SVGCalls style={{ marginRight: '10px' }} width={20} height={20} />
      case MATADOR_CONNECT:
        return <SVGChat style={{ marginRight: '10px' }} width={20} height={20} />
      default:
        return null
    }
  }

  useEffect(() => {
    ;(async () => {
      if (conversation && conversation._receiver_id) {
        const result = await customerActions.getVehicleInterest(
          conversation._receiver_id
        )
        if (result.success) {
          if (!_.isEmpty(result.data)) {
            const buyVehicle = Boolean(result.data['buy'])
              ? result.data['buy'][0]
              : ''
            const sellVehicle = Boolean(result.data['trade-in'])
              ? result.data['trade-in'][0]
              : ''
            authActions.setVehicleOfInterest({
              year: Boolean(buyVehicle) ? buyVehicle.year : '',
              make: Boolean(buyVehicle) ? buyVehicle.make : '',
              model: Boolean(buyVehicle) ? buyVehicle.model : '',
              vin: Boolean(buyVehicle) ? buyVehicle.vin : '',
              offer_amount:
                Boolean(buyVehicle) && buyVehicle.extraInfo.kbb_offer
                  ? buyVehicle.extraInfo.kbb_offer
                  : sellVehicle
                    ? sellVehicle.extraInfo.kbb_offer
                    : '',
              price_estimate:
                Boolean(buyVehicle) && buyVehicle.extraInfo.price_estimate
                  ? buyVehicle.extraInfo.price_estimate
                  : sellVehicle
                    ? sellVehicle.extraInfo.price_estimate
                    : '',
              trade_year: Boolean(sellVehicle) ? sellVehicle.year : '',
              trade_make: Boolean(sellVehicle) ? sellVehicle.make : '',
              trade_model: Boolean(sellVehicle) ? sellVehicle.model : '',
              trade_vin: Boolean(sellVehicle) ? sellVehicle.vin : '',
              data: result.data,
            })
          } else {
            authActions.setVehicleOfInterest({})
          }
        } else {
          authActions.setVehicleOfInterest({})
        }
      } else {
        authActions.setVehicleOfInterest({})
      }
    })()
  }, [conversation._id])

  useEffect(() => {
    const fillUsersTagList = async () => {
      let listUsersTag = []
      const fill = async (page) => {
        const resultUser = await userActions.getUsersForAssignUnassign(
          page,
          100,
          organization?._id,
          '',
          user.isSuperAdmin
            ? {
                fullList: true,
              }
            : {
                withoutSAdmins: true,
              }
        )
        if (resultUser.success && resultUser.data.length > 0) {
          if (listUsersTag.length < resultUser.max) {
            listUsersTag.push(...resultUser.data)
            await fill(page + 1)
          }
        }
      }
      await fill(0)
      setusersTagList(
        listUsersTag.map((item) => {
          return Object.assign(item, {
            display: item.fullName,
            value: item.fullName,
            type: 'user',
          })
        })
      )
    }
    fillUsersTagList()
  }, [organization?._id, user.location?._id])

  useEffect(() => {
    const fillEmailTemplates = async () => {
      let listEmailTemplates = []
      const fill = async (page = 0) => {
        const resultTemplets = await organizationActions.getPageListTemplets(
          page,
          100,
          organization?._id,
          '',
          EMAIL
        )

        if (resultTemplets.success && resultTemplets.data.length > 0) {
          if (listEmailTemplates.length < resultTemplets.max) {
            listEmailTemplates.push(...resultTemplets.data)
            await fill(page + 1)
          }
        }
      }
      await fill(0)
      setemailTemplatesList(listEmailTemplates)
    }
    const fillSMSTemplates = async () => {
      let listSMSTemplates = []
      const fill = async (page = 0) => {
        const resultTemplets = await organizationActions.getPageListTemplets(
          page,
          100,
          organization?._id,
          '',
          SMS
        )

        if (resultTemplets.success && resultTemplets.data.length > 0) {
          if (listSMSTemplates.length < resultTemplets.max) {
            listSMSTemplates.push(...resultTemplets.data)
            await fill(page + 1)
          }
        }
      }
      await fill(0)
      setsmsTemplatesList(listSMSTemplates)
    }

    fillEmailTemplates()
    fillSMSTemplates()
  }, [organization?._id])

  useImperativeHandle(ref, () => ({
    addNewMessage(item, fromSoket = true) {
      addToList(item, fromSoket)
    },
    resetFields() {
      setlistMessages([])
      setPage(0)
    },
    refreshMessage() {
      setRefreshMessage(true)
    },
    updateSmsStatus(newStatus, messageId) {
      const messageToUpdateIndex = listMessages.findIndex(
        (msg) => msg._id === messageId
      )
      if (messageToUpdateIndex !== -1) {
        const updatedMessages = listMessages.map((msg, index) => {
          if (index === messageToUpdateIndex) {
            msg.smsStatus = newStatus
          }
          return msg
        })
        setlistMessages(updatedMessages)
      }
    },
    removeScheduledMessage() {
      if (listMessages && listMessages.length) {
        const newList = listMessages.filter((item) => !item.isScheduled)
        setlistMessages(newList)
      }
    },
    removeRemindedMessage() {
      if (listMessages && listMessages.length) {
        setlistMessages((list) => {
          const newList = list.filter((item) => item.type !== REMINDER)
          return newList
        })
      }
    },
    updateInvitation(invitation) {
      const updatedMessages = listMessages.map((msg) => {
        if (msg.isInvitation && msg._id === invitation._id) {
          return { isInvitation: true, ...invitation }
        }
        return msg
      })
      setlistMessages(updatedMessages)
    },
    addNextScheduledMessage(nextMessage) {
      if (_.isEmpty(nextMessage)) {
        setNextScheduledMessages([])
      } else {
        setLoadingNextScheduledMessage(true)
        setNextScheduledMessages(nextMessage)
        setTimeout(() => {
          setLoadingNextScheduledMessage(false)
        }, 150)
      }
    },
    updateMessage: (data) => {
      if (listMessages?.length) {
        const updatedMessages = listMessages.map((msg) => {
          if (msg._id === data._id) {
            return data
          }
          return msg
        })
        setlistMessages(updatedMessages)
      }
    },
  }))

  const scrollToBottom = () => {
    centerScroll.current && centerScroll.current.scrollToBottom()
  }

  const findLastMessageForSummerize = (list) => {
    const aiItems = [SENT, BOT, REMINDER_BOT, AI_REPLY]
    let lastReceivedIndex = null
    let lastSentIndex = null

    const getLastIndex = (index, TYPE) => {
      if (!list[index].is_replied_back && !list[index].is_lead) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].type === TYPE) {
            lastReceivedIndex = index
          }
        }
      }
    }

    for (let i = 0; i < list.length; i++) {
      if (list[i].type === RECEIVED) {
        getLastIndex(i, RECEIVED)
      } else if (aiItems.includes(list[i].type)) {
        getLastIndex(i, list[i].type)
      } else if (
        list[i].type === APPOINTMENT ||
        list[i].isSummaries ||
        list[i].video ||
        list[i].isInvitation
      ) {
        lastSentIndex = i
      }
    }

    if (lastSentIndex !== null && lastReceivedIndex < lastSentIndex) {
      return null
    }

    return lastReceivedIndex !== null ? list[lastReceivedIndex] : null
  }

  const findNotSentMessageItem = (list) => {
    let lastReceivedIndex = null
    for (let index = list.length; index > 0; index--) {
      const realIndex = index - 1
      const element = list[realIndex]
      if (element?.type !== SENT && element?.whoSentThis_name !== SEQUENCE) {
        lastReceivedIndex = realIndex
        break
      }
    }
    return lastReceivedIndex !== null ? list[lastReceivedIndex] : null
  }

  useEffect(() => {
    const messageSomeActions = async () => {
      if (listMessages && listMessages.length) {
        const scheduledMessageExistAlready = listMessages.some(
          (msg) => msg.isScheduled
        )
        setisMessageScheduled(scheduledMessageExistAlready)
        let lastSmzMsg = findLastMessageForSummerize(listMessages)
        if (lastSmzMsg) {
          authActions.setAISummary(lastSmzMsg)
        } else {
          authActions.setAISummary(false)
        }
        let lastRcvMsg = findNotSentMessageItem(listMessages)
        if (lastRcvMsg) {
          setGetLastNotSentMessage(lastRcvMsg)
        } else {
          setGetLastNotSentMessage('')
        }
        const checkLastEmail = findLastEmailSentMessage(listMessages)
        if (
          checkLastEmail &&
          msgChangeEmailSMSType === PHONE &&
          conversation.receiver.email
        ) {
          connectFooter.current && connectFooter.current.setDefaultType(EMAIL)
        }
      } else {
        setisMessageScheduled(false)
      }
    }
    messageSomeActions()
  }, [listMessages, conversation])

  const addToList = (conv, fromSoket = true) => {
    if (
      (conv?._conversation_id ||
        conv?.conversation ||
        conv?.conversationId ||
        conv?._id) === conversation._id
    ) {
      if (fromSoket) {
        if (Array.isArray(conv)) {
          setlistMessages(conv)
        } else {
          setlistMessages((lastMessage) => {
            const updateMessageList = (conv, matchFn) => {
              let index = lastMessage.findIndex(matchFn)
              if (index > -1) {
                lastMessage[index] = conv
              } else {
                lastMessage.push(conv)
              }
              return [...lastMessage]
            }

            if (
              conv.video &&
              (conv.status === 'VIDEO_PROCESSING_SUCCEEDED' ||
                conv.status === 'VIDEO_PROCESSING_FAILED')
            ) {
              return updateMessageList(conv, (item) => item._id === conv._id)
            }

            if (conv.type === CONSENT_EDIT) {
              let list = lastMessage.filter((item) => item.type !== CONSENT_EDIT)
              list.push(conv)
              return list
            }

            const lastMsg = lastMessage[lastMessage.length - 1]

            if (conv.sentBy === INSTAGRAM) {
              conv._id = conv.messagingServiceSid
              lastMsg._id = lastMsg.messagingServiceSid
            }

            if (conv._id !== lastMsg?._id) {
              return updateMessageList(conv, (item) =>
                conv.sentBy === INSTAGRAM
                  ? item.messagingServiceSid === conv.messagingServiceSid
                  : item._id === conv._id
              )
            }

            if (conv.sentBy === INSTAGRAM) {
              return updateMessageList(
                conv,
                (item) => item.messagingServiceSid === conv.messagingServiceSid
              )
            }

            return [...lastMessage]
          })
        }
      } else {
        let lastMessage
        lastMessage = conv.messages.pop()
        conv.messages.push(lastMessage)
        conv.messages = [lastMessage]
        lastMessage.unread = false
        setlistMessages((lsMessage) => {
          let find = lsMessage.find((item) => {
            return item._id === lastMessage._id
          })
          if (!find) {
            lsMessage.push(lastMessage)
          }
          return [...lsMessage]
        })
        props.reorderToTop && props.reorderToTop(conv)
      }
      if (!conv.video) {
        scrollToBottom()
      }
    }
  }

  const MessageCenterContent = useMemo(() => {
    return (
      <>
        <MessageCenterHeader
          removeConversationFromList={(data, boolFirst, boolSecond) => {
            if (
              (history && history.location.state && !history.location.state.conv) ||
              Boolean(data)
            ) {
              removeConversationFromList &&
                removeConversationFromList(data, boolFirst, boolSecond)
            }
          }}
          closeCenterDrawer={closeCenterDrawer}
          messageType={messageType}
          conversation={conversation}
          addToList={addToList}
          showRightBox={showRightBox}
          refreshConversation={refreshConversation}
          isBlueExtension={isBlueExtension}
          additionalHeader={!loadingData && additionalHeader}
        />

        {
          <div
            className={`message_center_panel--center-list ${
              getMobileOperatingSystem() === 'iOS'
                ? 'message_center_panel--center-list-ios'
                : getMobileOperatingSystem() === 'iOS12'
                  ? 'message_center_panel--center-list-ios12'
                  : ''
            }`}
          >
            <AnimatePresence>
              <ListLoadMoreTop
                ref={centerScroll}
                // page={page}
                loading={loadingData}
                // isAnimated={true}
                totalDataCount={totalCount}
                data={listMessages}
                onLoadMore={() => {
                  setPage(page + 1)
                  setloadingData(true)
                }}
                renderItem={(item, index) => {
                  const lastSeenDate = item.LAST_SEEN
                  let lastSeenOneHourAgo = false
                  if (lastSeenDate) {
                    messageActions.setReceiver({
                      ...conversation.receiver,
                      activity: item,
                    })
                    const addOneHour = moment(lastSeenDate).utc().add(1, HOUR)
                    lastSeenOneHourAgo = moment().utc().isBefore(addOneHour)
                  }
                  if (item.isInvitation) {
                    return (
                      <CenterInvitation
                        conversation={conversation}
                        message={item}
                        index={index}
                      />
                    )
                  } else if (item.video) {
                    return item.status === 'VIDEO_PROCESSING_SUCCEEDED' ? (
                      <CenterVideo
                        conversation={conversation}
                        message={item}
                        index={index}
                      />
                    ) : item.status === 'VIDEO_PROCESSING_STARTED' ? (
                      <CenterMessageItem
                        index={index}
                        conversation={conversation}
                        message={item}
                        icon={<SVGPlayIcon width={16} height={16} />}
                        messageTitle={getText(
                          'TEXT_THIS_VIDEO_IS_PROCESSED_BY_THE_SYSTEM'
                        )}
                        messageBody={getText(
                          'TEXT_WE_WILL_SEND_MESSAGE_AUTOMATICALLY_ONCE_PROCESSING_IS_FINISHED'
                        )}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    ) : null
                  } else if (item.type === APPOINTMENT) {
                    const getAppointmentData = () => {
                      switch (item.action) {
                        case DELETED:
                          return {
                            icon: <SVGMessageAppointmentCanceledIcon />,
                            text: getText('TEXT_APPOINTMENT_WAS_CANCELED'),
                          }
                        case UPDATED:
                          return {
                            icon: <SVGMessageAppointmentUpdatedIcon />,
                            text: getText('TEXT_APPOINTMENT_WAS_UPDATED'),
                          }
                        default:
                          return {
                            icon: <SVGMessageAppointmentCreateIcon />,
                            text: getText('TEXT_APPOINTMENT_IS_BOOKED'),
                          }
                      }
                    }
                    return (
                      <CenterMessageItem
                        index={index}
                        conversation={conversation}
                        message={item}
                        date={item.eventStart}
                        messageTitle={getAppointmentData().text}
                        icon={getAppointmentData().icon}
                        hasFooter
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (
                    [
                      CALL,
                      VOICE_OUTGOING_CALL_FINISHED,
                      VOICE_OUTGOING_CALL_INITIATED,
                    ].includes(item.type)
                  ) {
                    return (
                      <CenterMessageItem
                        index={index}
                        conversation={conversation}
                        message={item}
                        icon={
                          item.type === VOICE_OUTGOING_CALL_FINISHED ? (
                            <SVGMessageIncomingFinishedIcon />
                          ) : (
                            <SVGMessageIncomingStartIcon />
                          )
                        }
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.type === OUTGOING_CALL) {
                    return (
                      <CenterMessageItem
                        index={index}
                        conversation={conversation}
                        message={item}
                        icon={<SVGMessageJoinCallIcon />}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.type === OUTGOING_CALL_LINK) {
                    return (
                      <CenterMessageItem
                        index={index}
                        conversation={conversation}
                        message={item}
                        icon={<SVGMessageJoinCallIcon />}
                        hasButtonText
                        buttonText={getText('WORD_JOIN_A_CALL')}
                        customButton
                        onClick={(e) => {
                          e.preventDefault()
                          if (isMobileApp) {
                            myWebViewPost(item.body)
                          } else {
                            window.open(item.body, '_blank')
                          }
                        }}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.type === PAYMENT) {
                    return (
                      <CenterPayment
                        conversation={conversation}
                        message={item}
                        index={index}
                        onCancelPayment={async (msg) => {
                          if (organizationPayments) {
                            const result = await paymentsActions.cancelPayment(
                              msg.additionalObject._id
                            )
                            if (result.success) {
                              let newListItems = listMessages.map((item) => {
                                if (item.additionalObject?._id === result.data._id) {
                                  item.additionalObject.status = result.data.status
                                }
                                return item
                              })
                              setlistMessages(newListItems)
                              notifySuccess(
                                getText('TEXT_THE_PAYMNET_CANCELED_SUCCESSFULLY')
                              )
                            } else {
                              notifyError(getTextServerError(result.errMsg))
                            }
                          } else {
                            notifyError(
                              getText('ERROR_YOU_DO_NOT_HAVE_ACCESS_TO_SEND_PAYMENT')
                            )
                          }
                        }}
                        onRefundPayment={async (msg) => {
                          if (organizationPayments) {
                            let result = await paymentsActions.refundPayment(
                              msg.additionalObject._id
                            )
                            if (result.success) {
                              let newListItems = listMessages.map((item) => {
                                if (item.additionalObject?._id === result.data._id) {
                                  item.additionalObject.status = result.data.status
                                }
                                return item
                              })
                              setlistMessages(newListItems)
                              notifySuccess(
                                getText('MSG_PAYMENT_REFUND_SUCCESSFULLY')
                              )
                            } else {
                              notifyError(getTextServerError(result.errMsg))
                            }
                          } else {
                            notifyError(
                              getText('ERROR_YOU_DO_NOT_HAVE_ACCESS_TO_SEND_PAYMENT')
                            )
                          }
                        }}
                      />
                    )
                  } else if (item.type === ASSIGN) {
                    const unassigned = item.body.includes(UNASSIGNED)
                    return (
                      <CenterMessageItem
                        index={index}
                        conversation={conversation}
                        message={item}
                        icon={
                          unassigned ? (
                            <SVGMessageUnsssignIcon />
                          ) : (
                            <SVGMessageAssignIcon />
                          )
                        }
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.type === ARCHIVE_STATUS) {
                    return (
                      <CenterMessageItem
                        index={index}
                        conversation={conversation}
                        message={item}
                        date={item.createdAt}
                        icon={<SVGMessageAssignIcon />}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.type === CONSENT_EDIT) {
                    const canRevert =
                      item.whoChangeConsent === 'Automations' &&
                      item.newStatus === RED &&
                      conversation._receiver_id
                    return (
                      <CenterMessageItem
                        message={item}
                        index={index}
                        conversation={conversation}
                        icon={<SVGMessageConsentIcon />}
                        customButton
                        buttonText={getText('TEXT_REVERT_AI_DECISION')}
                        onClick={async () => {
                          if (canRevert) {
                            let result = await customerActions.revertConsent(
                              conversation._receiver_id
                            )
                            if (result.success) {
                              notifySuccess(
                                getText('TEXT_CONSENT_WAS_SENT_SUCCESSFULLY')
                              )
                            } else {
                              notifyError(getTextServerError(result.errMsg))
                            }
                          }
                        }}
                        hasButtonText={
                          Boolean(canRevert) && !conversation.unsubscribed
                        }
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.sentBy === EMAIL) {
                    return (
                      <CenterEmail
                        message={item}
                        index={index}
                        conversation={conversation}
                        addSubject={(item) => {
                          connectFooter?.current?.addSubject(item)
                        }}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (lastSeenDate) {
                    return (
                      lastSeenOneHourAgo &&
                      index === listMessages.length - 1 && (
                        <CenterOnline
                          conversation={conversation}
                          lastSeenDate={lastSeenDate}
                        />
                      )
                    )
                  } else if (item.type === NOTE) {
                    const title = item.whoSentThis_name || item.user_name
                    return (
                      <CenterMessageItem
                        index={index}
                        conversation={conversation}
                        message={item}
                        messageTitle={title}
                        icon={<SVGMessageNoteIcon />}
                        messageBody={item.body || item.text}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.isScheduled) {
                    return (
                      <CenterMessageItem
                        conversation={conversation}
                        title={getText(
                          'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SCHEDULED_MESSAGE'
                        )}
                        hasFooter
                        icon={<SVGMessageSendLaterIcon />}
                        message={item}
                        index={index}
                        withUserName
                        hasButtonText
                        date={item.scheduleAt}
                        messageBody={item.message}
                        messageTitle={
                          item.type === 'EMAIL'
                            ? getText('TEXT_EMAIL_SCHEDULED_BY')
                            : getText('TEXT_MESSAGE_SCHEDULED_BY')
                        }
                        onChange={async () => {
                          let result = await messageActions.deleteScheduledMessage(
                            item._id
                          )
                          if (result.success) {
                            setloadingData(true)
                            let newListItems = listMessages.filter((it) => {
                              return it._id !== item._id
                            })
                            setlistMessages(newListItems)
                            setisMessageScheduled(false)
                            notifySuccess(
                              getText('TEXT_SCHEDULED_MESSAGE_DELETED_SUCCESSFULLY')
                            )
                            setloadingData(false)
                          } else {
                            notifyError(getTextServerError(result.errMsg))
                          }
                        }}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.isReminded) {
                    return (
                      <CenterMessageItem
                        buttonPlaceChanged
                        conversation={conversation}
                        index={index}
                        title={getText(
                          'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_REMINDED_MESSAGE'
                        )}
                        hasFooter
                        hasButtonText
                        icon={<SVGMessageRemindMeIcon />}
                        message={item}
                        date={item.notifyAt}
                        buttonText={getText(
                          !moment().isSameOrAfter(conversation.preferred_nudge_time)
                            ? 'ACTION_DELETE'
                            : 'WORD_DISMISS'
                        )}
                        messageTitle={getText(
                          !moment().isSameOrAfter(conversation.preferred_nudge_time)
                            ? 'REMINDER_WAS_SET_TO_FOLLOW_UP_WITH_THIS_CUSTOMER'
                            : 'TEXT_REMINDER_HAS_BEEN_TRIGGERED'
                        )}
                        onChange={async () => {
                          let result = await messageActions.deleteRemindedMessage(
                            item._id
                          )
                          if (result.success) {
                            setloadingData(true)
                            let newListItems = listMessages.filter((it) => {
                              return it._id !== item._id
                            })
                            setlistMessages(newListItems)
                            notifySuccess(
                              getText('TEXT_REMINDED_MESSAGE_DELETED_SUCCESSFULLY')
                            )
                            setloadingData(false)
                          } else {
                            notifyError(getTextServerError(result.errMsg))
                          }
                        }}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.type === EXPORT) {
                    return (
                      <CenterMessageItem
                        conversation={conversation}
                        index={index}
                        icon={<SVGPushToCRMIcon />}
                        message={item}
                        messageTitle={getText('TEXT_CONVERSATION_SYNCED_WITH_CRM')}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.bubble) {
                    return null
                  } else if (item.type === TAG_USER) {
                    return (
                      <CenterMessageItem
                        index={index}
                        conversation={conversation}
                        message={item}
                        messageTitle={`${
                          item.whoSentThis_name
                        } tagged ${item.taggedUsers.map(
                          (item) => ' ' + item.firstName + ' ' + item.lastName
                        )}`}
                        icon={'@'}
                        messageBody={addTagBeforeText(item, true)}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.type === BOT_CANCELLED) {
                    return (
                      <CenterMessageItem
                        index={index}
                        conversation={conversation}
                        message={item}
                        icon={'x'}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.isSummaries) {
                    return (
                      <CenterMessageItem
                        index={index}
                        conversation={conversation}
                        message={item}
                        messageTitle={getText('WORD_SUMMARY')}
                        icon={
                          <SVGAISummaryIcon
                            width={16}
                            height={16}
                            color={MainColor}
                          />
                        }
                        messageBody={item.text}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.type === CONVERSATION_CREATED) {
                    return (
                      <CenterMessageItem
                        index={index}
                        conversation={conversation}
                        message={item}
                        icon={<SVGConversationCreated />}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.type === SUBSCRIBE) {
                    return (
                      <CenterMessageItem
                        conversation={conversation}
                        index={index}
                        icon={<SVGMessageSubscribeIcon />}
                        message={item}
                        messageTitle={item.body}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.type === UNSUBSCRIBE) {
                    return (
                      <CenterMessageItem
                        conversation={conversation}
                        index={index}
                        icon={<SVGMessageUnsubscribeIcon />}
                        message={item}
                        messageTitle={item.body}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.type === SKIP_SCHEDULED_MESSAGE) {
                    return (
                      <CenterMessageItem
                        conversation={conversation}
                        index={index}
                        icon={<SVGMessageSkippedIcon />}
                        message={item}
                        messageTitle={item.body}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  }
                  if (item.type === REACTIVATE) {
                    return (
                      <CenterMessageItem
                        conversation={conversation}
                        index={index}
                        icon={<SVGMessageUnskippedIcon />}
                        message={item}
                        messageTitle={item.body}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  }
                  if (item.type === SNOOZE) {
                    return (
                      <CenterMessageItem
                        conversation={conversation}
                        index={index}
                        icon={<SVGMessageSkippedIcon />}
                        message={item}
                        messageTitle={item.body}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  }

                  if (item.type === SUPERHUMAN_CHAT_SESSION) {
                    return (
                      <CenterAIBChatBlock
                        index={index}
                        conversation={conversation}
                        message={item}
                        icon={
                          <SVGConversationIcon
                          // width={16}
                          // height={16}
                          // color={MainColor}
                          />
                        }
                        messageBody={item.text}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  }

                  if (item.type === VOICE_AI) {
                    return (
                      <CenterVoiceAIBlock
                        index={index}
                        conversation={conversation}
                        message={item}
                        icon={
                          <SVGVoiceAIMessagesIcon
                          // width={16}
                          // height={16}
                          // color={MainColor}
                          />
                        }
                        messageBody={item.text}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else if (item.type === OUTBOUND_VOICE_AI) {
                    return (
                      <CenterVoiceAIBlock
                        index={index}
                        conversation={conversation}
                        message={item}
                        icon={<SVGVoiceAIMessagesIcon />}
                        messageBody={item.text}
                        lastNotSentMessage={getLastNotSentMessage}
                        messageListType={messageType}
                      />
                    )
                  } else {
                    return (
                      <CenterOther
                        lastNotSentMessage={getLastNotSentMessage}
                        conversation={conversation}
                        message={item}
                        index={index}
                        getIconSource={getIconSource}
                        messageListType={messageType}
                      />
                    )
                  }
                }}
              />
            </AnimatePresence>
          </div>
        }

        {loadingData ? null : (
          <>
            {!loadingNextScheduledMessage
              ? nextScheduledMessages.map((item, index, arr) => {
                  if (
                    (item.send_via === EMAIL && !conversation?.receiver?.email) ||
                    (item.send_via === SMS && !conversation?.receiver?.phone)
                  )
                    return null

                  const otherScheduled = arr.find((it) =>
                    item.send_via === SMS
                      ? it.send_via === EMAIL
                      : item.send_via === EMAIL
                        ? it.send_via === SMS
                        : false
                  )
                  if (
                    otherScheduled &&
                    dayjs(otherScheduled?.action_time).isBefore(
                      dayjs(item.action_time)
                    ) &&
                    ((otherScheduled?.send_via === SMS &&
                      conversation?.receiver?.phone) ||
                      (otherScheduled?.send_via === EMAIL &&
                        conversation?.receiver?.email))
                  ) {
                    return null
                  }

                  if (
                    item.exists &&
                    organization?.superhumanConfig?.enabled &&
                    conversation?.superhumanReply?.exists
                  ) {
                    return (
                      <CenterMessageSuperhumanSnooze
                        key={index}
                        disabled={
                          !userActions.getUserHasPermission(
                            user,
                            organization?.restrictions
                              ?.allowSuperhumanActionInConversation
                          )
                        }
                        onSnooz={async () => {
                          const result = await messageActions.snoozeSuperhumanAction(
                            conversation._id,
                            true
                          )
                          if (!result) {
                            notifyError(getTextServerError(result.errMsg))
                          }
                        }}
                        isExtension={isExtension}
                      />
                    )
                  } else if (
                    nextScheduledMessageEnabled &&
                    item.type === AUTO_REPLY
                  ) {
                    return (
                      <CenterSkipScheduledMessage
                        key={index}
                        message={item}
                        conversation={conversation}
                        disabled={
                          !userActions.getUserHasPermission(
                            user,
                            organization?.restrictions
                              ?.allowNextScheduledSequenceMessage
                          )
                        }
                        onSkip={async () => {
                          let result = await messageActions.skipScheduledMessages(
                            conversation._id,
                            'DRIP_SEQUENCE',
                            item._id
                          )
                          if (result.success) {
                            setloadingData(true)
                            notifySuccess(
                              getText('TEXT_SCHEDULED_MESSAGE_SKIPPED_SUCCESSFULLY')
                            )
                            setloadingData(false)
                          } else {
                            notifyError(getTextServerError(result.errMsg))
                          }
                        }}
                      />
                    )
                  } else if (item.type === GENERATED) {
                    return (
                      <CenterSkipScheduledMessage
                        key={index}
                        message={item}
                        conversation={conversation}
                        disabled={
                          !userActions.getUserHasPermission(
                            user,
                            organization?.restrictions
                              ?.allowNextScheduledSequenceMessage
                          )
                        }
                        onSkip={async () => {
                          let result = await messageActions.skipScheduledMessages(
                            conversation._id,
                            'FOLLOW_UP',
                            item._id
                          )
                          if (result.success) {
                            setloadingData(true)
                            notifySuccess(
                              getText('TEXT_SCHEDULED_MESSAGE_SKIPPED_SUCCESSFULLY')
                            )
                            setloadingData(false)
                          } else {
                            notifyError(getTextServerError(result.errMsg))
                          }
                        }}
                      />
                    )
                  }
                  return null
                })
              : null}
            <MessageCenterFooter
              conversation={conversation}
              ref={connectFooter}
              onSendSuccess={(conv) => {
                if (isBlueExtension && !conversation.id && conversation.isNew) {
                  refreshConversation &&
                    refreshConversation(conv, false, false, true)
                } else {
                  addToList(conv, false)
                  refreshConversation && refreshConversation(conv, true, true, false)
                }
              }}
              unarchiveConversation={async () => {
                const result = await messageActions.unarchive(conversation._id)
                if (result.success) {
                  props.removeConversationFromList(result.data.conversation, false)
                }
              }}
              unAssignConversation={(data) => {
                removeConversationFromList(data, false)
              }}
              addToMessageList={(data) => {
                addToList(data)
              }}
              isMessageScheduled={isMessageScheduled}
              onChangeEmailSMSType={onChangeEmailSMSType}
              msgChangeEmailSMSType={msgChangeEmailSMSType}
              changeMessageType={changeMessageType}
              messageType={messageType}
              isExtension={isExtension}
              draftedMessage={draftedMessage}
              isLastMessageConsent={
                listMessages[listMessages.length - 1] &&
                listMessages[listMessages.length - 1].type === 'consent'
              }
              smsTemplatesList={smsTemplatesList}
              setsmsTemplatesList={setsmsTemplatesList}
              emailTemplatesList={emailTemplatesList}
              setemailTemplatesList={setemailTemplatesList}
              usersTagList={usersTagList}
              refreshConversation={refreshConversation}
              isBlueExtension={isBlueExtension}
            />
          </>
        )}
      </>
    )
  }, [
    loadingData,
    conversation,
    listMessages,
    page,
    perPage,
    showRightBox,
    refreshConversation,
    totalCount,
    loadingNextScheduledMessage,
    organization?.superhumanConfig?.enabled,
    organization?.restrictions?.allowSuperhumanActionInConversation,
    getLastNotSentMessage,
    emailTemplatesList,
    smsTemplatesList,
    isMessageScheduled,
    usersTagList,
  ])

  return (
    <>
      {isEmpty ? (
        <div className='message-list-center-no-conversation'>
          <SVGMessageCenterNoData />
        </div>
      ) : (
        MessageCenterContent
      )}
    </>
  )
})

export default memo(MessageCenter)
